@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'util/mixins';
@import 'motion-ui';
@include foundation-everything;

// @include foundation-global-styles;
// @include foundation-grid;

// new for flexbox integration
// @include foundation-flex-grid;
// @include foundation-flex-classes;

// @include foundation-typography;
// @include foundation-button;
// @include foundation-forms;
// @include foundation-visibility-classes;
// @include foundation-float-classes;
// @include foundation-accordion;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
// @include foundation-callout;
// @include foundation-close-button;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
// @include foundation-dropdown-menu;
// @include foundation-flex-video;
// @include foundation-label;
// @include foundation-media-object;
// @include foundation-menu;
// @include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
// @include foundation-reveal;
// @include foundation-switch;
// @include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
// @include foundation-tooltip;
// @include foundation-top-bar;

@include motion-ui-transitions;
@include motion-ui-animations;

@import "mmenu";
@import "jquery.mmenu.positioning";
@import "site";
@import "../../../bower_components/animatewithsass/animate.scss";
@import "../../../bower_components/fullpage.js/jquery.fullPage.scss";
@import "../../../bower_components/slick-carousel/slick/slick.scss";
@import "../js/_new-rs-9.5.7/royalslider/royalslider";
@import "../js/_new-rs-9.5.7/royalslider/skins/default/rs-default";

$fi-path: "../../../bower_components/foundation-icon-fonts/foundation-icons";
// $b72-path: "../fonts/BodoniSvtyTwoITCTT/";

@import "../../../bower_components/foundation-icon-fonts/foundation-icons";
@font-face {
  	font-family: "foundation-icons";
  	src: url("#{$fi-path}.eot");
  	src: url("#{$fi-path}.eot?#iefix") format("embedded-opentype"),
         url("#{$fi-path}.woff") format("woff"),
         url("#{$fi-path}.ttf") format("truetype"),
         url("#{$fi-path}.svg#fontcustom") format("svg");
  	font-weight: normal;
  	font-style: normal;
}
// @font-face {
// 	font-family:'BodoniSvtyTwoOSITCTTBook';
// 	font-family:'BodoniSeventytwoITCW01-_731142';
// 	src: url('#{$b72-path}BodoniSvtyTwoOSITCTTBook_gdi.eot');
// 	src: url('#{$b72-path}BodoniSvtyTwoOSITCTTBook_gdi.eot?#iefix') format('embedded-opentype'),
// 		 url('#{$b72-path}BodoniSvtyTwoOSITCTTBook_gdi.woff') format('woff'),
// 		 url('#{$b72-path}BodoniSvtyTwoOSITCTTBook_gdi.ttf') format('truetype'),
// 		 url('#{$b72-path}BodoniSvtyTwoOSITCTTBook_gdi.svg#BodoniSvtyTwoOSITCTTBold') format('svg');
// 	font-weight: 350;
// 	font-style: normal;
// 	font-stretch: normal;
// 	unicode-range: U+0020-25CA;
// }
// @font-face {
// 	font-family:'BodoniSvtyTwoOSITCTTBold';
// 	src: url('#{$b72-path}BodoniSvtyTwoOSITCTTBold_gdi.eot');
// 	src: url('#{$b72-path}BodoniSvtyTwoOSITCTTBold_gdi.eot?#iefix') format('embedded-opentype'),
// 		 url('#{$b72-path}BodoniSvtyTwoOSITCTTBold_gdi.woff') format('woff'),
// 		 url('#{$b72-path}BodoniSvtyTwoOSITCTTBold_gdi.ttf') format('truetype'),
// 		 url('#{$b72-path}BodoniSvtyTwoOSITCTTBold_gdi.svg#BodoniSvtyTwoOSITCTTBold') format('svg');
// 	font-weight: 350;
// 	font-style: normal;
// 	font-stretch: normal;
// 	unicode-range: U+0020-25CA;
// }
// @font-face {
// 	font-family:'BodoniSvtyTwoSCITCTTBook';
// 	font-family:'ITCBodoniStytwoW01-BkSC';
// 	src: url('#{$b72-path}BodoniSvtyTwoSCITCTTBook_gdi.eot');
// 	src: url('#{$b72-path}BodoniSvtyTwoSCITCTTBook_gdi.eot?#iefix') format('embedded-opentype'),
// 		 url('#{$b72-path}BodoniSvtyTwoSCITCTTBook_gdi.woff') format('woff'),
// 		 url('#{$b72-path}BodoniSvtyTwoSCITCTTBook_gdi.ttf') format('truetype'),
// 		 url('#{$b72-path}BodoniSvtyTwoSCITCTTBook_gdi.svg#BodoniSvtyTwoOSITCTTBold') format('svg');
// 	font-weight: 350;
// 	font-style: normal;
// 	font-stretch: normal;
// 	unicode-range: U+0020-25CA;
// }