/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//GLOBAL STYLES
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
* {
    list-style-type: none;
    outline: none;
}

*:before {
    box-sizing: content-box !important;
}

input[type="file"],
input[type="checkbox"],
input[type="radio"],
select {
    margin: 0 !important;
}

// Colors
$juju-green: rgb(186, 210, 158);
$juju-light-green: #DEE283;
$juju-blue: rgb(215, 224, 235);
$juju-orange: rgb(255, 193, 144);
$juju-violet: rgb(218, 207, 221);
$juju-red: rgb(233, 192, 197);
$juju-dark-red: darken($juju-red, 30%);
$juju-yellow: rgb(243, 218, 154);
$juju-paper: #FFFEF3;
$juju-brown: #9C8073;
$juju-caramel: #CCB394;
$juju-ash-brown: #A59995;
$juju-peach: #FFBB9D;
$juju-clay: #CC7863;
$juju-slate-green: #556869;
// $juju-paper: lighten($juju-orange, 20%);
$red-ash: #544547;
$blue-ash: #7f8e96;
$warm-ash: #C1B8AF;
$green-ash-light: #F1F0E7;
$blue-ash-light: #E9EBF1;
$dark-ash: #80766d;
$bright-red: #FF6363;
$splash-logo: darken($warm-ash, 50%);
$slpash-hover: #FFF6E2;
$book: #F7EED9;
$night: #181717;
$ultralight: #f2f2f2;
$footer-lite: #97a4a9;
$alt-brown: #5F5753;
$oil: #2f2f2f;
$bright-red: #de0000;
$ultralight: #eaeaea;
// Fonts
$bodoni-book: 'BodoniSeventytwoITCW01-_731142',
Georgia,
Times,
sans-serif;
// $bodoni-bold: "BodoniSvtyTwoOSITCTTBold",
// Georgia,
// Times,
// sans-serif;
$bodoni-sc-book: 'ITCBodoniStytwoW01-BkSC',
Georgia,
Times,
sans-serif;
$standard-kerning: 2px;
$border-light: 1px solid lighten($black, 80%) !important;
$border-light-thick: 4px solid lighten($black, 80%) !important;
$border-ultralight: 1px solid #EAEAEA !important;
$single-transition: all .3s ease-in-out;
// Globals
html {
    position: relative;
}

body {
    position: relative;
    height: 100% !important;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background-color: $white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $bodoni-sc-book;
}

.columns {
    position: relative;
}

@mixin cookies-n-cream {
    background: -moz-linear-gradient(left, $warm-ash 0%, $warm-ash 50%, darken($warm-ash, 5%) 50%, darken($warm-ash, 5%) 100%);
    background: -webkit-linear-gradient(left, $warm-ash 0%, $warm-ash 50%, darken($warm-ash, 5%) 50%, darken($warm-ash, 5%) 100%);
    background: linear-gradient(to right, $warm-ash 0%, $warm-ash 50%, darken($warm-ash, 5%) 50%, darken($warm-ash, 5%) 100%);
}

@mixin anchor {
    font-family: $bodoni-sc-book;
    font-size: 15px;
    color: $black;
    letter-spacing: $standard-kerning;
    @include -motion-ui-defaults;
    &:hover {
        color: $juju-dark-red;
        // border-bottom: 1px solid;
    }
}

@mixin text-input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 1.5rem;
    padding: 0.5rem;
    border: $border-light;
    margin: 0 0 1rem;
    font-family: $bodoni-book;
    font-size: 15px;
    color: $dark-gray;
    background-color: $white;
    box-shadow: none;
    border-radius: 0;
    transition: border-color 0.25s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    @include -motion-ui-defaults;
    &:focus {
        border: lighten($black, 60%);
        background-color: $white;
        outline: none;
        box-shadow: none;
        transition: border-color 0.25s ease-in-out;
    }
}

@mixin unbutton {
    border: none;
    background-color: $white;
}

@mixin center-button {
    display: block;
    margin: 0 auto;
}

[type='text'],
[type='email'],
[type='number'] {
    @include text-input;
}

@mixin quote-borders {
    border-top: $border-ultralight;
    border-bottom: $border-ultralight;
}

.top-bar {
    background-color: transparent;
    -webkit-font-smoothing: antialiased;
    @include -motion-ui-defaults;
}

// .top-bar.transition {
//     transform: translateY(-6rem) translate3d(0, 0, 0);
// }
.button {
    @include button(true, $black, lighten($black, 20%), $white);
    padding: .3rem 1rem .45rem 1rem;
    width: 100%;
    font-family: $bodoni-sc-book;
    text-transform: lowercase;
    letter-spacing: $standard-kerning;
}

.media-object img {
    max-width: 100%;
}

.button.open {
    @include button(true, $white, $black, auto);
    padding: .3rem 1rem .45rem 1rem;
    width: 100%;
    font-family: $bodoni-sc-book;
    text-transform: lowercase;
    letter-spacing: $standard-kerning;
    border: $border-light;
}

.button.open.sold-out {
    @include button(true, $white, $black, auto);
    padding: .3rem 1rem .45rem 1rem;
    width: 100%;
    font-family: $bodoni-sc-book;
    text-transform: lowercase;
    letter-spacing: $standard-kerning;
    border: $border-ultralight;
    color: darken($ultralight, 10%);
    cursor: not-allowed;
}

.button.round {
    @include button($white, $black, auto);
    padding: .3rem;
    padding: .25rem;
    width: 1.5rem;
    height: 1.5rem;
    border: $border-light;
    border-radius: 50%;
}

.button.open:hover {
    color: $white;
    border: 1px solid transparent;
}

.add-to-cart input.button.open.sold-out:focus,
.add-to-cart input.button.open.sold-out:hover {
    color: darken($ultralight, 15%) !important;
    border: inherit;
    background: transparent;
}

p {
    font-family: $bodoni-book;
}

.lead {
    @include breakpoint(small) {
        font-size: 110%;
    }
    @include breakpoint(medium) {
        font-size: 115%;
    }
    @include breakpoint(large) {
        font-size: 125%;
    }
    @include breakpoint(xxlarge) {
        font-size: 150%;
    }
}

.hed {
    font-family: $bodoni-book;
    text-align: center;
    @include breakpoint(large) {
        margin-top: 5rem;
        margin-bottom: 0;
    }
}

.copy-block {
    @include breakpoint(large) {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

@mixin small-header {
    font-family: $bodoni-sc-book;
    font-size: 15px;
    color: $black;
    letter-spacing: $standard-kerning;
    text-align: center;
    text-transform: lowercase;
    @include -motion-ui-defaults;
}

.small-header {
    @include small-header;
}

// Custom Classes
.row {
    max-width: none;
    @include breakpoint(small) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    @include breakpoint(medium) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    @include breakpoint(large) {
        padding-left: 6rem;
        padding-right: 6rem;
    }
}
.fixed {
    position: fixed;
}

.large-collapse {
    @include breakpoint(large) {
        @include grid-column-collapse;
    }
}

.medium-collapse {
    @include breakpoint(medium only) {
        @include grid-column-collapse;
    }
}

.small-collapse {
    @include breakpoint(small only) {
        @include grid-column-collapse;
    }
}

.collapse {
    @include grid-column-collapse;
}

.clearfix {
    clear: both;
}

.small-centered, 
.medium-centered, 
.large-centered {
    float: none !important;
    clear: both;    
}

.tp {
    @include breakpoint(small) {
        padding-top: 1rem;
    }
    @include breakpoint(medium) {
        padding-top: 2rem;
    }
}

.tbp {
    @include breakpoint(small) {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    @include breakpoint(medium) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

.dtbp {
    @include breakpoint(small) {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    @include breakpoint(medium) {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    @include breakpoint(large) {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
}

.white {
    color: $white !important;
}

.bg_juju-red {
    background: lighten($juju-red, 15%);
}

.bg_juju-green {
    background: lighten($juju-green, 15%);
}

.bg_juju-violet {
    background: lighten($juju-violet, 10%);
}

.bg_juju-blue {
    background: lighten($juju-blue, 10%);
}

.bg_juju-yellow {
    background: lighten($juju-yellow, 10%);
}

.bg_juju-orange {
    background: lighten($juju-orange, 15%);
}

.bg_juju-peach {
    background: lighten($juju-peach, 15%);
}

.bg_juju-paper {
    background: $juju-paper;
}

.bg_juju-clay {
    background: lighten($juju-clay, 20%);
}

.bg_juju-brown {
    background: lighten($juju-brown, 20%);
}

.bg_juju-slate-green {
    background: lighten($juju-slate-green, 20%);
}

.bg_juju-ash-brown {
    background: lighten($juju-ash-brown, 15%);
}


.vertical-content-wrapper {
    @include breakpoint(small) {
        display: table;
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
    @include breakpoint(large) {
        display: table;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.vertical-content-outer-wrapper {
    height: 100%;
}

.vertical-center {
    display: table-cell;
    vertical-align: middle;
}

.vertical-content-wrapper.reflow-mobile {
    @include breakpoint(small) {
        display: block;
        position: relative;
        width: 100%;
        margin-top: 4rem;
    }
    @include breakpoint(large) {
        display: table;
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
}

.vertical-center.reflow-mobile {
    @include breakpoint(small) {
        display: block;
        position: relative;
    }
    @include breakpoint(medium) {
        display: block;
        position: relative;
    }
    @include breakpoint(large) {
        display: table-cell;
        vertical-align: middle;
    }
}

.svg-inner-wrapper {
    height: 0;
    margin: 0 auto;
    position: relative;
    transition: $single-transition;
    @include breakpoint(small) {
        width: 6rem;
        padding-top: 6rem;
    }
    @include breakpoint(medium) {
        width: 7rem;
        padding-top: 7rem;
    }
    @include breakpoint(large) {
        width: 10rem;
        padding-top: 10rem;
    }
    @include breakpoint(xxlarge) {
        width: 13rem;
        padding-top: 13rem;
    }
}

g#wreath {
    -webkit-transform-origin: 50% 50% !important;
    -ms-transform-origin: 50% 50% !important;
    transform-origin: 50% 50% !important;
    transform: rotate(2.75deg);
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//MAIN CONTENT
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
#site-container {
    background: $white;
}

.main-content {
    @include grid-col-collapse;
}

main.columns {
    @include breakpoint(large) {
        @include grid-col-collapse;
    }
    height: 100%;
}

#content {
    // @include flex-grid-row;
    height: 100%;
    max-width: 100%;
    overflow: hidden;
    min-height: 100%;
    @include breakpoint(small only) {
        height: 100% !important;
    }
    @include breakpoint(medium only) {
        height: 100% !important;
    }
    @include breakpoint(large) {
        padding-left: 0;
        padding-right: 0;
    }
}

section {
    z-index: 1;
    @include -motion-ui-defaults;
}

section.transparent {
    background: transparent !important;
}

#first {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background: $juju-yellow;
    z-index: 2;
}

.fixed-slide {
    margin: 0;
    background: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
}

section#products {
    z-index: 7;
    // overflow-x: hidden;
}

// body[data-whatinput="mouse"] section {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-size:cover;
// }
// body[data-whatinput="touch"] section {
//     position: relative;
// }
h1.section-header {
    width: 100%;
    text-align: center;
    font-family: $bodoni-book;
    color: $black;
    background-color: $white;
    @include -motion-ui-defaults;
}

h1.section-header {
    @include breakpoint(small) {
        padding: 3rem 0 1rem 0;
    }
    @include breakpoint(medium) {
        padding: 3rem 0 3rem 0;
    }
    @include breakpoint(large) {
        padding: 4rem 0 4rem 0;
    }
    @include breakpoint(xxlarge) {
        padding: 5rem 0 5rem 0;
    }
}

#heroSlider {
    height: auto;
    width: auto;
}

#hero {
    position: relative;
    @include breakpoint(small) {
        margin-top: 3.8rem;
    }
    @include breakpoint(large) {
        margin-top: 11rem;
    }
}

#hero .hero-title-wrapper {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 37;
}

#hero .rsGCaption {
    display: table-cell;
    vertical-align: middle;
    position: absolute;
    top: 0;
    bottom: 0;
}

#hero #hero-caption {
    display: table-cell;
    vertical-align: middle;
    background: rgba(127, 85, 33, 0.35);
}

#hero #hero-caption span {
    display: inline-block;
    position: relative !important;
    margin: 0 auto;
    max-width: 80%;
    color: $white;
    font-family: $bodoni-book;
    opacity: 0;
    transform: translateY(3rem) translate3d(0, 0, 0);
    transition-duration: .6s;
    transition-timing-function: ease-in-out;
    transition-delay: .2s;
    @include breakpoint(small) {
        font-size: 2.5rem;
        line-height: 3rem;
    }
    @include breakpoint(medium) {
        font-size: 3rem;
        line-height: 3.5rem;
    }
    @include breakpoint(large) {
        font-size: 3.5rem;
        line-height: 4rem;
    }
    @include breakpoint(xxlarge) {
        font-size: 5rem;
        line-height: 5.5rem;
    }
}

#hero blockquote {
    @include quote-borders;
    @include breakpoint(small) {
        margin: 2rem 0 3rem 0;
        padding: 3rem 0;
    }
    @include breakpoint(medium) {
        margin: 1rem 0 3rem 0;
        padding: 3rem 0;
    }
    @include breakpoint(large) {
        margin: 6rem 0 6rem 0;
        padding: 3rem 0;
    }
    @include breakpoint(xlarge) {
        margin: 12rem 0 12rem 0;
        padding: 3rem 0;
    }
}

body.loaded #hero-caption span.fade--in--up {
    opacity: 1 !important;
    transform: translateY(0) translate3d(0, 0, 0) !important;
}

.mscroll {
    position: absolute;
    bottom: 3rem;
    left: 50%;
    margin-left: -15px;
    width: 30px;
    height: 50px;
    background: none;
    transition: all .5s ease;
    z-index: 30;
    @include breakpoint(small) {
        display: none;
    }
    @include breakpoint(large) {
        display: block;
    }
}

.mscroll:before {
    content: "scroll";
    color: $white;
    font-family: $bodoni-sc-book;
    position: absolute;
    bottom: 1rem;
    left: 50%;
    margin-left: -20px;
    width: 40px;
    height: 15px;
    -webkit-animation: scrollanimation 7s infinite;
    animation: scrollanimation 7s infinite;
    -webkit-animation-delay: 3s;
    animation-delay: 4s;
    z-index: 30;
}

.mscroll:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $white;
    -webkit-animation: scrollanimation 7s infinite;
    animation: scrollanimation 7s infinite;
    -webkit-animation-delay: 3s;
    animation-delay: 4s;
    z-index: 30;
}

@keyframes scrollanimation {
    0% {
        transform: translate3d(0, 5px, 5px);
    }
    12% {
        transform: translate3d(0, 16px, 16px);
    }
    24% {
        transform: translate3d(0, 5px, 5px);
    }
    36% {
        transform: translate3d(0, 16px, 16px);
    }
    48% {
        transform: translate3d(0, 5px, 5px);
    }
    60% {
        transform: translate3d(0, 16px, 16px);
    }
    72%,
    100% {
        transform: translate3d(0, 5px, 5px);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.fade--in {
    animation: fadeIn ease-in 1;
    animation-duration: .3s;
}

.fade--out {
    animation: fadeOut ease-in 1;
    animation-duration: .3s;
}

.fade-in-up {
    animation-name: fadeInUp;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(0) translate3d(0, 0, 0);
    }
    100% {
        opacity: 1;
        transform: translateY(-20px) translate3d(0, 0, 0);
    }
}

.animated {
    @include -motion-ui-defaults;
    animation-fill-mode: both;
}

#site-preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: lighten($warm-ash, 20%);
    z-index: 9999;
}

#status {
    width: 250px;
    height: 250px;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    background: url(../img/juju_logo-ko_wa.svg) center no-repeat;
    margin: -125px 0 0 -125px;
    opacity: 0;
    @include -motion-ui-defaults;
    @include breakpoint(small) {
        transform: scale(.4);
    }
    @include breakpoint(medium) {
        transform: scale(.5);
    }
    @include breakpoint(xxlarge) {
        transform: scale(.75);
    }
}

#status.visible {
    opacity: 1;
}

#global-preloader {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: $white;
    z-index: -1;
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#global-preloader img {
    animation: bobble 1s cubic-bezier(0.6, 1, 1, 1) infinite;
    @include breakpoint(small) {
        min-width: 1.75rem;
    }
    @include breakpoint(large) {
        min-width: 2rem;
    }
}

@keyframes bobble {
    0% {
        opacity: 0;
        filter: alpha(opacity=0);
    }
    35% {
        opacity: .5;
        filter: alpha(opacity=100);
    }
    100% {
        opacity: 0;
        filter: alpha(opacity=0);
    }
}

#intro {
    @include breakpoint(small) {
        padding: .25rem 1rem 1rem;
    }
    @include breakpoint(medium) {
        padding: 2rem 2rem;
    }
    @include breakpoint(large) {
        padding: 4rem 4rem;
        float: none;
    }
}

.block-column img {
    @include breakpoint(small) {
        padding-bottom: 1rem;
    }
    @include breakpoint(medium) {
        padding-bottom: 2rem;
    }
    @include breakpoint(large) {
        padding-bottom: 0;
    }
}

.gracenote {
    opacity: .2;
    @include breakpoint(small) {
        margin: 1rem 0;
    }
    @include breakpoint(medium) {
        margin: 4rem 0;
    }
}

.gracenote img {
    @include breakpoint(small) {
        width: 25px;
    }
    @include breakpoint(medium) {
        width: 40px;
    }
}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//TOPBAR / HEADER
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
a.home {
    position: absolute;
    left: 50%;
    z-index: 1002;
    @include breakpoint (small) {
        margin-left: -32px;
        margin-top: 6px;
    }
    @include breakpoint (large) {
        margin-left: -86.265px;
        margin-top: 1.5rem;
    }
}

#tagline {
    // border-top: 1px solid #989898;
    // border-bottom: 1px solid #989898;
    padding-bottom: 2px;
    margin-top: 5px;
    font-family: $bodoni-sc-book !important;
    color: $dark-gray;
}

.sh_pct {
    font-size: 10px;
}

.logo-wrapper {
    width: 100%;
    height: 125px;
    margin: 0 auto;
    padding: 0;
}

.logo-inner-wrapper {
    height: 0;
    margin: 0 auto;
    position: relative;
    opacity: .8;
    border-radius: 50%;
    background: $warm-ash;
    @include -motion-ui-defaults;
    @include breakpoint(small) {
        width: 3.25rem;
        padding-top: 3.25rem;
    }
    @include breakpoint(large) {
        width: 5rem;
        padding-top: 5rem;
    }
    &:hover {
        background: darken($warm-ash, 5%);
    }
}

.logo-inner-wrapper svg {
    @include breakpoint(small) {
        position: absolute;
        top: 0;
        left: 0;
        width: 3rem;
        height: 3rem;
        margin-left: .25rem;
        margin-top: .25rem;
    }
    @include breakpoint(large) {
        position: absolute;
        top: 0;
        left: 0;
        width: 4rem;
        height: 4rem;
        margin-left: .5rem;
        margin-top: .5rem;
    }
}

.logo-inner-wrapper img {
    padding: .5rem;
}

.top-bar-left .home {
    padding-top: 0;
    padding-bottom: 0;
}

.top-bar-right .menu {
    display: initial;
}

#shopping-bag-toggle {
    float: right;
}

.menu-text {
    padding: 0;
}

#juju-logo,
#juju-logo-small {
    position: absolute;
    top: 0;
    left: 0;
}

#juju-logo-small path {
    @include -motion-ui-defaults;
    // fill: $white;
}

// #juju-logo-small:hover path {
//     fill: darken($juju-red, 30%)
// }

// #juju-logo path {
//     @include -motion-ui-defaults;
//     // fill: $splash-logo;
//     // fill: darken($juju-red, 40%);
//     // fill: $juju-peach;
//     // fill: $white;
//     fill: lighten($black, 20%);
// }
// #juju-logo g#juju-script path {
//     // fill: lighten($splash-logo, 5%);
//     // fill: $juju-peach;
//     // fill: $white;
//     fill: lighten($black, 20%);
// }
// #juju-logo:hover path {
//     fill: darken($juju-red, 30%) !important;
// }
.top-bar {
    padding: 0;
    z-index: 1000;
    @include breakpoint(small) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        border-bottom: 2px solid $black;
        background-color: $white;
    }
    @include breakpoint(large) {
        border-bottom: none;
        background: transparent;
    }
}

.top-bar li {
    text-align: center;
}

.top-bar li a,
.top-bar li button {
    display: block !important;
    padding: 0 1rem !important;
}

.top-bar li a,
.top-bar li a span,
.top-bar li button {
    text-transform: lowercase;
    font-size: 16px;
    @include anchor;
    @include -motion-ui-defaults;
}

// .top-bar li a:hover #juju-logo-small path {
//     fill: darken($juju-peach, 20%) !important;
// }

// .top-bar li a:hover,
// .top-bar li a:hover span,
// .top-bar li a.active,
// .top-bar li a.active span,
// .top-bar li button {
//     color: darken($juju-red, 30%);
// }

li.modal-toggle-wrapper {
    @include breakpoint(small) {
        margin: 1rem 0 1.3rem 1rem;
    }
    @include breakpoint(large) {
        margin: 3rem 3rem 3rem 3rem;
    }
}

li#shopping-bag-toggle {
    @include breakpoint(small) {
        margin: 1rem;
    }
    @include breakpoint(large) {
        margin: 3rem;
    }
}

.reveal.full {
    top: 0 !important;
    background: $night;
    // background: $juju-caramel;
    @include breakpoint(small) {
        padding: 1rem;
    }
    @include breakpoint(large) {
        padding: 2rem;
    }
    @include breakpoint(xlarge) {
        padding: 3rem;
    }
}

.reveal #reveal-menu {
    @include breakpoint(small) {
        padding-top: 3rem;
    }
    @include breakpoint(large) {
        padding-top: 7rem;
    }
}

.reveal .reveal-menu-header {
    font-family: $bodoni-book;
    color: $white;
}

.reveal a.product-item {
    @include breakpoint(small) {
        font-size: 1.5rem;
    }
    @include breakpoint(large) {
        font-size: 2.5rem;
    }
}

.reveal a.menu-item,
.reveal .reveal-menu-header {
    display: table;
    font-size: 1.5rem;
    @include breakpoint(medium) {
        margin-bottom: 1rem;
    }
    @include breakpoint(large) {
        margin-bottom: 2rem;
    }
}

.reveal a.menu-item.social {
    margin-bottom: 0;
}

.reveal a.product-item,
.reveal a.menu-item {
    font-family: $bodoni-book;
    color: $white;
    // border-bottom: 1px solid transparent;
    @include -motion-ui-defaults;
    &:hover {
        // border-bottom: 1px solid $white;
        color: $medium-gray;
    }
}

#products-topnav>li a.scrub:hover {
    color: darken($juju-yellow, 10%);
}

#products-topnav>li a.cleanser:hover {
    color: darken($juju-red, 10%);
}

#products-topnav>li a.cream:hover {
    color: darken($juju-violet, 10%);
}

#products-topnav>li a.dry-serum:hover {
    color: darken($juju-orange, 10%);
}

#products-topnav>li a.eye-serum:hover {
    color: darken($juju-blue, 10%);
}

#products-topnav>li a.toner:hover {
    color: darken($juju-green, 10%);
}

#products-topnav>li a.family:hover {
    color: darken($warm-ash, 10%);
}

#products-nav-modal button.close-button {
    color: $white;
    left: 50%;
    margin-left: -13px;
    font-size: 3rem;
    @include -motion-ui-defaults;
    @include breakpoint(small) {
        top: 0rem;
        right: inherit;
    }
    @include breakpoint(large) {
        top: 2rem;
    }
    &:hover {
        color: $medium-gray;
    }
}

#products-topnav>li.last {
    margin-bottom: 3rem;
}

#fc #shopping-bag-toggle,
#fc #hamburger {
    display: none;
}

// html:not(#fc) #menu>a {
//     top: -3px;
// }
#hamburger {
    width: 22px;
    height: 24px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

#hamburger span:nth-child(1) {
    top: 7px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#hamburger span:nth-child(2) {
    top: 14px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#hamburger span:nth-child(3) {
    top: 21px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#hamburger span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $black;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
}

.menu > li > a svg {
    margin: 0 !important;
}

.top-bar #shopping-bag-toggle {
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    cursor: pointer;
}

.top-bar #shopping-bag-toggle {
    display: block;
    padding: 0;
}

.top-bar li a {
    padding: 0 !important;
}

.top-bar #shopping-bag-toggle svg {
    overflow: visible;
}

.top-bar #shopping-bag-toggle svg g.handle {
    transform: matrix(1, 0, 0, 1, 0, 0);
    @include -motion-ui-defaults;
}

.top-bar #shopping-bag-toggle:hover svg g.handle {
    transform: matrix(1, 0, 0, 1, 0, -2);
}

.top-bar #shopping-bag-toggle span.bag-count {
    display: block;
    height: 20px;
    position: absolute;
    bottom: 7px;
    text-align: center;
    font-size: 12px !important;
    font-family: $bodoni-book !important;
    line-height: 20px;
    letter-spacing: 1px !important;
    color: $black;
    @include breakpoint(small) {
        width: 23px;
    }
    // @include breakpoint(medium) {
    //     width: 22px;
    // }
    // @include breakpoint(large) {
    //     width: 25px;
    // }
    // @include breakpoint(xlarge) {
    //     width: 27px;
    // }
    @include breakpoint(xxlarge) {
        width: 23px;
    }
}

.top-bar a {
    vertical-align: middle;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//MOBILE TOPBAR
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
#m-nav {
    width: 100%;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//SIDENAV
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
#sidenav {
    display: none;
    position: fixed;
    top: 50%;
    left: 1rem;
    margin-left: 0;
    margin-top: -50px;
    margin-bottom: 0;
    list-style-type: none;
    z-index: 2;
    li {
        margin: 0;
        width: 20px;
        height: 20px;
        position: relative;
        a {
            margin: 0 auto;
            display: block;
            height: 5px;
            width: 5px;
            border-radius: 30px;
            background-color: $white;
            position: relative;
            top: 50%;
            opacity: .3;
            @include -motion-ui-defaults;
            &:hover {
                opacity: 1;
            }
        }
    }
    li.current {
        a {
            display: block;
            border-radius: 50%;
            opacity: 1;
        }
    }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//BACKGROUND CHECK
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.pp-family li#related {
    display: none !important;
}

.top-bar.background--dark a,
.top-bar.background--dark a span,
.top-bar.background--dark button {
    color: $white !important;
    fill: $white !important;
    stroke: $white !important;
}

.top-bar.background--light #hamburger span {
    background: $black;
}

.top-bar.background--dark #hamburger span,
.top-bar.background--dark #hamburger span {
    background: $white;
}

.top-bar.background--light svg#cart_icon g.handle path,
.top-bar.background--light svg#cart_icon g.handle path circle {
    fill: $black;
}

.top-bar.background--light svg#cart_icon rect {
    stroke: $black;
}

.top-bar.background--dark svg#cart_icon g.handle path,
.top-bar.background--dark svg#cart_icon g.handle path circle {
    fill: $white;
}

.top-bar.background--dark svg#cart_icon rect {
    stroke: $white;
}

.top-bar.background--light #shopping-bag-toggle span.bag-count {
    color: $black !important;
}

.top-bar.background--dark #shopping-bag-toggle span.bag-count {
    color: $white !important;
}

// story section bgc
.top-bar.background--light.reverse #hamburger span {
    background: $white;
}

.top-bar.background--light.reverse #shopping-bag-toggle span.bag-count {
    color: $white !important;
}

.top-bar.background--light.reverse svg#cart_icon rect {
    stroke: $white;
}

.top-bar.background--light.reverse svg#cart_icon g.handle path,
.top-bar.background--light.reverse svg#cart_icon g.handle path circle {
    fill: $white;
}

// all others bgc
.top-bar.background--dark.stop #hamburger span {
    background: $black;
}

.top-bar.background--dark.stop #shopping-bag-toggle span.bag-count {
    color: $black !important;
}

.top-bar.background--dark.stop svg#cart_icon rect {
    stroke: $black;
}

.top-bar.background--dark.stop svg#cart_icon g.handle path,
.top-bar.background--dark.stop svg#cart_icon g.handle path circle {
    fill: $black;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//OFFCANVAS BAG
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.mm-page {
    height: 100%;
}

.mm-menu {
    max-width: 440px !important;
}

.mm-panels > .mm-panel {
    padding: 0 !important;
}

.update-button {
    font-family: $bodoni-sc-book;
    font-size: 15px;
    color: $black;
    letter-spacing: $standard-kerning;
    @include unbutton;
    @include center-button;
    @include -motion-ui-defaults;
    margin-bottom: .5rem;
    &:hover {
        color: darken($juju-red, 30%);
    }
}

.mm-listview > li:not(.mm-divider):after {
    border-bottom: none !important;
}

.shopping-bag {
    background-color: transparent !important;
}

.shopping-bag:not(.mm-menu) {
    display: none;
}

.shopping-bag .mm-title {
    display: none !important;
}

// .shopping-bag>.mm-panels {
//     display: none;
// }
.shopping-bag ul {
    margin: 0;
}

.shopping-bag:not( .mm-menu) {
    display: none;
}

.shopping-bag #global-cart-stats {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 1rem;
    z-index: 99;
}

.shopping-bag #global-cart-stats .subtotal {
    font-family: $bodoni-sc-book;
    text-transform: lowercase;
}

.shopping-bag #global-cart-stats .subtotal .st-right {
    float: right;
}

.shopping-bag .message {
    text-align: center;
    margin-top: 1rem;
}

.shopping-bag thead {
    background-color: $white;
    border: none;
}

.shopping-bag th {
    font-family: $bodoni-sc-book;
    font-weight: normal;
    text-transform: lowercase;
    letter-spacing: $standard-kerning;
}

.bag-item {
    padding: .5rem;
    border-bottom: $border-light;
}

.bag-item li {
    padding: 0;
}

.bag-item h1,
.bag-item h2 {
    font-size: 15px;
    margin-top: .5rem;
}

.bag-item h1,
.bag-item td {
    font-family: $bodoni-book;
    font-weight: normal;
}

.bag-item [type='number'] {
    width: 100% !important;
}

.image-wrapper>a {
    display: block;
    @include breakpoint(large) {
        padding: 2rem;
    }
}

.image-wrapper {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.04);
    overflow: hidden;
    position: relative;
    text-align: center;
    border-radius: 4px;
}

.image-overlay-content {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    bottom: 0;
    left: 0;
}

.overlay-fade-in p {
    font-family: $bodoni-sc-book;
    color: $white;
    font-size: 16px;
    opacity: 0;
    transition: all 0.2s linear;
}

.overlay-fade-in img {
    transition: all 0.2s linear;
}

.overlay-fade-in .image-overlay-content {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.4);
    transition: all 0.4s ease-in-out;
}

.overlay-fade-in h2 {
    font-family: $bodoni-book;
    color: $white;
    font-size: 18px;
    margin-top: 40%;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.overlay-fade-in .button {
    display: inline-block;
    text-decoration: none;
    padding: 7px 14px;
    background: #FFF;
    color: #222;
    text-transform: uppercase;
    box-shadow: 0 0 1px #000;
    position: relative;
    border: 1px solid #999;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.overlay-fade-in .button:hover {
    box-shadow: 0 0 5px #000;
}

// .overlay-fade-in:hover img {
//     transform: scale(1.2);
// }
.overlay-fade-in:hover .image-overlay-content {
    opacity: 1;
}

.overlay-fade-in:hover h2,
.overlay-fade-in p,
.overlay-fade-in .button {
    opacity: 1;
}

.overlay-fade-in:hover p {
    transition-delay: 0.1s;
}

.overlay-fade-in:hover .button {
    transition-delay: 0.2s;
}

.overlay-slide-in-left img {
    transition: all 0.3s ease-in-out;
}

.overlay-slide-in-left .image-overlay-content {
    background-image: url(http://www.awwwards.com/awards/images/2015/04/pattern.jpg);
    background-color: rgba(0, 0, 0, 0.3);
    transform: translateX(-110%);
    opacity: 1;
    transition: all 0.4s ease-in-out;
    box-shadow: 0 0 7px #ccc;
}

.overlay-slide-in-left h2 {
    font-size: 1.8rem;
    background: rgba(255, 255, 255, 0.7);
    margin-top: 40%;
    color: #000;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
}

.overlay-slide-in-left p {
    opacity: 0;
    color: #333;
    transition: all 0.2s linear;
}

.overlay-slide-in-left .button {
    display: inline-block;
    text-decoration: none;
    padding: 7px 14px;
    background: #000;
    color: #fff;
    text-transform: uppercase;
    box-shadow: 0 0 1px #000;
    position: relative;
    border-radius: 7px;
}

.overlay-slide-in-left .button:hover {
    background-color: #444;
}

.overlay-slide-in-left:hover .image-overlay-content {
    transform: translateX(0px);
}

.overlay-slide-in-left:hover img {
    transform: translateX(80%);
    transition-delay: 0.1s;
}

.overlay-slide-in-left:hover p {
    opacity: 1;
    transition-delay: 0.4s;
}

.overlay-fade-in-new-background .button {
    display: inline-block;
    text-decoration: none;
    padding: 7px 14px;
    background: #000;
    color: #fff;
    text-transform: uppercase;
    border-radius: 5px;
    box-shadow: 0 0 1px #000;
    position: relative;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.overlay-fade-in-new-background .button:hover {
    box-shadow: 0 0 5px #fff;
    background-color: #222;
}

.overlay-fade-in-new-background p {
    font-size: 28px;
}

.overlay-fade-in-new-background .image-overlay-content {
    opacity: 0;
    background-image: url(http://subtlepatterns.com/patterns/gplaypattern.png);
    background-color: rgba(255, 255, 255, 0.5);
    transition: all 0.4s ease-in-out;
}

.overlay-fade-in-new-background h2 {
    color: #000;
    font-size: 1.6rem;
    margin-top: 30%;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.overlay-fade-in-new-background p {
    opacity: 0;
    transition: all 0.2s linear;
}

.overlay-fade-in-new-background img {
    transition: all 0.2s ease-in-out;
}

.overlay-fade-in-new-background:hover img {
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
    filter: gray;
    -webkit-filter: grayscale(100%);
    transform: scale(1.5);
}

.overlay-fade-in-new-background:hover .image-overlay-content {
    opacity: 1;
}

.overlay-fade-in-new-background:hover h2,
.overlay-fade-in-new-background p,
.overlay-fade-in-new-background .button {
    opacity: 1;
}

.overlay-fade-in-new-background:hover p {
    transition-delay: 0.1s;
}

.overlay-fade-in-new-background:hover .button {
    transition-delay: 0.2s;
}

// .bag-item {
//     width: 100%;
//     height: 125px;
//     margin: 0 auto;
//     padding: 0;
// }
// .bag-item img {
//     position: absolute;
//     top: 0;
//     left:0;
// }
// .bag-item-inner {
//     height: 0;
//     margin: 0 auto;
//     position: relative;
//     @include breakpoint(small) {
//         width: 8rem;
//         padding-top: 8rem;
//     }
//     @include breakpoint(medium) {
//         width: 9rem;
//         padding-top: 9rem;
//     }
//     @include breakpoint(large) {
//         width: 10rem;
//         padding-top: 10rem;
//     }
//     @include breakpoint(xxlarge) {
//         width: 12rem;
//         padding-top: 12rem;
//     }
// }
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//FOXYCART STYLE REVERSALS
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@mixin foxy-reset-header {
    font-family: $bodoni-sc-book !important;
    font-size: 16px !important;
    font-weight: normal !important;
    color: $black !important;
    letter-spacing: .5px !important;
    font-style: normal !important;
    text-transform: lowercase !important;
}

@mixin foxy-reset-header-light {
    font-family: $bodoni-sc-book !important;
    font-size: 16px !important;
    font-weight: normal !important;
    color: lighten($black, 30%) !important;
    font-style: normal !important;
    text-transform: lowercase !important;
}

@mixin foxy-reset-header-small {
    font-family: $bodoni-sc-book !important;
    font-size: 15px !important;
    font-weight: normal !important;
    letter-spacing: $standard-kerning;
    color: $black !important;
    font-style: normal !important;
    text-transform: lowercase !important;
}

@mixin foxy-reset-header-ko {
    font-family: $bodoni-sc-book !important;
    font-size: 15px !important;
    font-weight: normal !important;
    text-transform: lowercase;
    color: $white !important;
    letter-spacing: $standard-kerning;
    text-transform: lowercase !important;
}

@mixin foxy-reset-paragraph {
    font-family: $bodoni-book !important;
    font-size: 16px !important;
    font-weight: normal !important;
    color: $black !important;
}

@mixin foxy-reset-paragraph-ko {
    font-family: $bodoni-book !important;
    font-size: 16px !important;
    font-weight: normal !important;
    color: $white !important;
}

@mixin foxy-reset-text-input {
    display: block !important;
    box-sizing: border-box !important;
    width: 100%;
    height: 1.5rem !important;
    padding: 0 0.5rem !important;
    border: $border-ultralight;
    margin: 0 0 1rem;
    font-family: $bodoni-book !important;
    font-size: 15px !important;
    color: $dark-gray !important;
    background-color: $white !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    transition: border-color 0.25s ease-in-out !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    @include -motion-ui-defaults;
    &:focus {
        background-color: $white !important;
        outline: none !important;
        box-shadow: none !important;
        transition: border-color 0.25s ease-in-out !important;
    }
}

@mixin foxy-reset-button-open {
    @include button(true,
    $white,
    $black,
    auto);
    padding: .3rem 1rem .45rem 1rem !important;
    width: 100% !important;
    color: #0a0a0a !important;
    font-family: $bodoni-sc-book !important;
    text-transform: lowercase !important;
    letter-spacing: $standard-kerning !important;
    border: $border-ultralight;
    border-color: $border-ultralight;
    vertical-align: middle !important;
    border-radius: 0 !important;
    margin: 0 1rem 1rem 0 !important;
    font-size: 0.9rem !important;
    background-color: #fefefe !important;
    background: none !important;
    text-align: center !important;
    line-height: 1 !important;
    cursor: pointer !important;
    text-shadow: none !important;
    &:hover {
        background: $black !important;
        color: $white !important;
        border-color: transparent !important;
    }
}

@mixin foxy-reset-anchor {
    font-family: $bodoni-sc-book !important;
    font-size: 15px !important;
    color: $black !important;
    text-transform: lowercase !important;
    letter-spacing: $standard-kerning !important;
    @include -motion-ui-defaults;
    &:hover {
        color: $juju-dark-red;
    }
}

@mixin foxy-reset-button-strip {
    @include foxy-reset-header-small;
    color: lighten($black, 20%);
    border: none !important;
    background: transparent !important;
    background-color: transparent !important;
    padding: 0 !important;
    &:hover {
        color: darken($juju-red, 30%) !important;
    }
}

@mixin foxy-sidebar {
    @include breakpoint(small) {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
    @include breakpoint(large) {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }
}

.fc-container__grid,
.fc-sidebar--cart {
    @include foxy-sidebar;
}

#fc .fc-container__row,
#fc .fc-checkout__main,
#fc .fc-sidebar--cart .fc-transaction,
#fc .fc-sidebar--cart .fc-address-entry,
#fc .fc-sidebar--cart .fc-actions--payment,
#fc .fc-transaction__shipping-address,
#fc .fc-cart__item__options {
    margin: 0 !important;
}

#fc .container,
#fc .fc-container,
#fc .fc-cart__item,
#fc .fc-transaction,
#fc .fc-sidebar--cart .fc-transaction,
#fc .fc-sidebar--cart .fc-address-entry,
#fc .fc-sidebar--cart .fc-actions--payment,
#fc .fc-cart__item__details,
#fc .fc-subtotal__label {
    padding: 0 !important;
}

#fc .fc-cart__item {
    border-bottom: $border-ultralight;
    margin-bottom: 1rem !important;
}

.fc-cart__item__details-and-image #fc .fc-cart__item__details {
    padding-left: 0 !important;
}

.fc-cart__item__details-and-image {
    width: 70% !important;
}

#fc #fc-cart--fixed-width .fc-cart__item__total {
    padding-right: 0 !important;
}

.fc-cart__item__totals {
    width: 30% !important;
}

.fc-form-label--city-options,
#fc .fc-cart__title__header,
#fc .fc-transaction__header,
.fc-location.fc-form-control,
.fc-container__grid--country {
    display: none !important;
}

#fc table {
    margin-bottom: 0;
}

table thead,
table tbody,
table tfoot {
    border: none;
    border-left: none !important;
    border-right: none !important;
}

#fc h4,
#fc .h4,
#fc h5,
#fc .h5,
#fc h6,
#fc .h6,
.fc-order-quantity,
.fc-order-quantity span,
.fc-subtotal__value,
#fc .fc-transaction__total,
{
    @include foxy-reset-header;
}

#fc-cart-form .fc-cart__item__total p,
#fc-cart-form .fc-cart__item__price__each {
    font-family: $bodoni-sc-book !important;
    text-transform: lowercase !important;
}

.fc-subtotal__value {
    padding: 0 !important;
    margin-top: 10px;
    margin-bottom: 10px;
}

.fc-cart__title.fc-container__grid {
    text-align: center;
    margin-bottom: 2rem !important;
}

.fc-order-quantity {
    width: inherit;
    display: initial;
    padding: .25rem .65rem !important;
    border: $border-ultralight;
    border-radius: 15px;
}

#fc .fc-subtotal__label {
    @include foxy-reset-header;
}

.fc-subtotal__label h5 {
    margin-bottom: .25rem !important;
}

#fc .fc-subtotal--row {
    border-bottom: $border-ultralight;
}

#fc .fc-input-group-container--active {
    border-radius: 0 !important;
}

.mm-navbar {
    border-bottom: none !important;
    text-align: center;
    line-height: 33px;
    height: 40px;
    padding: 0 !important;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100% !important;
}

[data-fc-sidecart] .fc-sidecart-only-fixed {
    width: 100% !important;
}

nav.fc-sidecart-only-fixed {
    display: none !important;
}

#fc .fc-input-group-container--radio input[type="radio"] {
    margin: .55rem .5rem .5rem 0 !important;
}

#fc .fc-shipping-rates__rate .fc-form-label--shipping-rate {
    padding-left: 0 !important;
    display: flex !important;
}

#fc .fc-cart__item__quantity .fc-cart__item__quantity__input.fc-form-control {
    float: right !important;
}

.fc-form-label--shipping-rate {
    display: flex !important;
    padding-left: .5rem !important;
}

.fc-form-label--postal-code {
    display: none !important;
}

.fc-shipping-rates__rate__name,
.fc-shipping-rates__rate__value {
    float: none !important;
    @include foxy-reset-header-light;
    text-align: right;
}

.fc-input-group-container--active .fc-shipping-rates__rate__value {
    color: $black !important;
}

[data-fc-sidecart] .fc-fixed-button-left,
[data-fc-sidecart] .fc-fixed-button-right {
    width: 50% !important;
    height: 33px !important;
}

#fc .fc-address-entry .fc-postal-code-entry__submit button {
    text-shadow: none !important;
    border: none !important;
    background: $medium-gray !important;
    border-radius: 0 !important;
    padding-bottom: 0 !important;
    margin-left: 0 !important;
    height: 28px;
    padding: 0 5px !important;
    margin-top: -1px;
    line-height: 0 !important;
    @include foxy-reset-header-ko;
    @include -motion-ui-defaults;
    &:hover {
        background: $black !important;
    }
}

#fc .fc-address-entry .fc-alert-container--error .fc-alert--danger {
    background: $medium-gray !important;
    text-shadow: none !important;
    @include foxy-reset-paragraph-ko;
}

.fc-shipping-rates__rate {
    margin-bottom: 0 !important;
    padding: 0 !important;
    background: transparent !important;
    border-bottom: $border-ultralight;
}

.fc-fixed-button-left--text,
[data-fc-sidecart] .fc-fixed-button-left span {
    @include foxy-reset-anchor;
    &:hover {
        background: transparent !important;
    }
}

[data-fc-sidecart] .fc-fixed-button-right {
    width: 50% !important;
}

#fc .fc-sidebar--cart .fc-transaction,
#fc .fc-sidebar--cart .fc-address-entry {
    border-bottom: none !important;
}

#fc .fc-sidebar {
    background: $white !important;
}

#fc .fc-btn-action {
    padding-top: .3rem !important;
    padding-right: 1rem !important;
    padding-bottom: .45rem !important;
    padding-left: 1rem !important;
}

#fc .fc-fixed-button-right .fc-btn-action {
    background-color: $medium-gray !important;
    background: $medium-gray !important;
    letter-spacing: $standard-kerning;
    @include foxy-reset-header-ko;
    @include -motion-ui-defaults;
    &:hover {
        background-color: $black !important;
        background: $black !important;
        color: $white !important;
    }
}

#fc .form-control,
#fc .fc-form-control,
#fc .fc-form-control,
#fc .fc-form-control:focus {
    @include foxy-reset-text-input;
    min-height: 28px !important;
}

#fc .fc-cart__items .fc-cart__item__remove a,
#fc .fc-transaction__ui__toggle {
    @include foxy-reset-button-strip;
    &:focus {
        outline: none !important;
    }
}

#fc h4 {
    color: $black !important;
    font-family: $bodoni-sc-book !important;
    text-transform: lowercase;
    font-size: 16px !important;
}

.fc-alert li {
    @include foxy-reset-paragraph-ko;
}

#fc p,
#fc .fc-cart__item__name,
#fc .fc-transaction__shipping-address {
    @include foxy-reset-paragraph;
    text-transform: none !important;
}

#fc p#tagline {
    font-family: $bodoni-sc-book !important;    
}

#fc a:hover, #fc a:focus {
    text-decoration: initial !important;
}

#fc a.home:hover p#tagline {
    text-decoration: initial !important;
}

#fc .fc-transaction__shipping-address {
    border: none !important;
    text-align: center;
    background: $light-gray;
    border-radius: 0 !important;
    margin-bottom: .5rem !important;
}

[data-fc-sidecart] #fc-cart {
    padding-top: 3rem !important;
}

#fc .fc-sidecart-button:hover {
    background: transparent !important;
    background-color: !important;
}

.mm-iconpanel .mm-panel.mm-opened {
    border: none !important;
    width: 100% !important;
    min-width: 100% !important;
}

#fc .fc-sidebar,
#fc .fc-messages .fc-alert li {
    background-color: transparent !important;
}

#fc .fc-messages__empty-notification {
    background: $white !important;
    background-color: $white !important;
    border: none !important;
    border-radius: 0 !important;
}

#fc .fc-messages__empty-notification a {
    font-family: $bodoni-book !important;
    font-size: 2rem !important;
    line-height: 2.25rem;
    color: $dark-gray !important;
    @include -motion-ui-defaults;
    &:hover {
        text-decoration: none !important;
        color: darken($juju-red, 30%);
    }
}

#fc .fc-input-group-container--radio input[type="radio"] {}

#fc .fc-sidebar .fc-actions--payment {
    position: relative;
    margin-top: 2rem !important;
    padding: 0 !important;
    width: 100%;
    left: 0;
    bottom: 0;
}

#fc .fc-action--checkout--button,
#fc .fc-button--subscription-cancel,
#fc .fc-button--submit {
    @include foxy-reset-button-open;
    .fc-svg-icon {
        display: none !important;
    }
}

#fc .fc-transaction__total .fc-subtotal__value {
    padding: 0 !important;
    @include foxy-reset-header;
}

[data-fc-sidecart] #fc-cart.fc-container {
    background-color: transparent !important;
}

#fc .fc-messages .fc-alert {
    margin: 0 !important;
    padding: 1rem !important;
    background-color: lighten($black, 30%) !important;
}

#fc .alert,
#fc .fc-alert,
#fc .fc-alert--danger {
    background-color: lighten($black, 30%) !important;
    border: none !important;
    border-radius: 0 !important;
}

#fc .fc-svg-icon.fc-icon-large {
    height: .75rem !important;
    width: .75rem !important;
}

.fc-form-label .fc-form-label--postal-code {
    position: relative !important;
}

.fc-sidebar input#shipping_postal_code,
.fc-sidebar select.fc-form-control--city-options {
    border: $border-ultralight;
}

#fc .fc-sidebar .fc-form-control {
    border: $border-ultralight;
}

ul.fc-foxycomplete-list {
    border: $border-ultralight;
    top: 27px !important;
}

#fc .fc-foxycomplete-list li {
    border-top: $border-ultralight;
    height: 28px !important;
}

#fc .fc-foxycomplete-list li p {
    padding: .15rem .5rem !important;
}

#fc .sr-only,
#fc .fc-form-label--city-options,
#fc .fc-form-label--city,
#fc .fc-form-label--region,
#fc .fc-form-label--customer-email,
#fc .fc-form-label--customer-password,
#fc .fc-form-label--new-cc-number,
#fc .fc-form-label--new-cc-exp-year,
#fc .fc-form-label--plastic-new__text,
#fc .fc-container__grid--input--first-name .fc-form-label,
#fc .fc-container__grid--input--last-name .fc-form-label,
#fc .fc-container__grid--input--phone .fc-form-label,
#fc .fc-container__grid--input--company .fc-form-label,
#fc .fc-container__grid--input--tax-id .fc-form-label,
#fc .fc-container__grid--input--address1 .fc-form-label,
#fc .fc-container__grid--label--address2 .fc-form-label {
    position: relative !important;
    overflow: inherit !important;
}

// CHECKOUT
* {
    outline: 0 !important;
}

#fc .fc-checkout {
    @include breakpoint(medium) {
        margin-top: 3rem;
    }
    @include breakpoint(large) {
        margin-top: 5rem;
    }
}

#fc .fc-checkout .fc-checkout__main .fc-sidebar--checkout {
    @include breakpoint(large) {
        padding-right: 0 !important;
        padding-left: 7px !important;
    }
    @include breakpoint(medium) {
        padding-left: 15px !important;
    }
}

#fc body {
    background: $white !important;
    padding: 0 !important;
}

#fc .fc-checkout .fc-cancel-continue-shopping a.fc-button {
    letter-spacing: .75px !important;
    text-align: center !important;
    padding: .3rem 0 .4rem !important;
}

#fc .fc-checkout .fc-cart__item__total p {
    font-family: $bodoni-sc-book !important;
    text-transform: lowercase !important;
}

#fc .fc-checkout #fc-checkout-container {
    border-radius: 0 !important;
    padding-top: 5px;
}

#fc .fc-checkout fieldset {
    margin: 0 !important;
}

#fc .fc-checkout .fc-checkout__main__contents,
#fc .fc-checkout .fc-container__grid--input--plastic-new-card-number,
#fc .fc-checkout .fc-input-group-container__title.fc-form-label,
#fc .fc-checkout .fc-cart__item__details,
#fc .fc-checkout .fc-cart__item__totals,
#fc .fc-checkout .fc-cart__main__content.fc-container__grid,
#fc .fc-checkout .fc-sidebar.fc-sidebar--cart,
#fc .fc-checkout .fc-cancel-continue-shopping.fc-container__grid,
#fc .fc-checkout .fc-password-actions.fc-list--inline li {
    padding: 0 !important;
}

#fc .fc-checkout #fc-cart {
    padding-top: 23px;
}

#fc .fc-checkout .fc-checkout__main__contents .fc-address-entry .fc-form-label,
#fc .fc-checkout legend,
#fc .fc-checkout h1,
#fc .fc-checkout #fc-receipt-container #fc-cart--responsive .fc-cart__title__header,
#fc .fc-checkout .fc-section-counter,
#fc .fc-checkout .fc-checkout__section--payment #fc-icon-lock-large,
#fc .fc-checkout .fc-svg-icon.fc-icon-small,
#fc .fc-checkout #fc-cart--fixed-width .fc-cart__title,
#fc .fc-checkout .fc-checkout__section--payment .fc-payment-method--plastic__new .fc-svg-icon--lock,
#fc .fc-checkout .fc-form-label--new-cc-exp-month,
#fc .fc-checkout label.fc-form-label.fc-form-label--new-cc-number,
#fc .fc-checkout .fc-input-group-container__title.fc-form-label,
#fc .fc-checkout .fc-form-label.fc-form-label--new-cc-cvv2,
#fc .fc-checkout label.fc-form-label.fc-form-label--customer-password,
#fc .fc-checkout .fc-svg-icon.fc-svg-icon--lock.visible-xs.visible-sm {
    display: none !important;
}

#fc .fc-checkout .col-sm-offset-3,
#fc .fc-container__grid--use-different-address,
#fc .fc-checkout .fc-container__grid--input--customer-email,
#fc .fc-checkout .fc-container__grid--authentication-button--amazon,
#fc .fc-checkout .fc-container__grid--checkout-submit,
#fc .fc-checkout .fc-container__grid--checkout-subscription-cancel,
#fc .fc-checkout .fc-container__grid--checkout-submit-helper-text,
#fc .fc-checkout .fc-container__grid--checkout-subscription-cancel-helper-text,
#fc .fc-checkout .fc-container__grid--input--company,
#fc .fc-container__grid--input--phone,
#fc .fc-checkout .fc-container-checkout__grid--postal-code,
#fc .fc-checkout .fc-input-group-container--radios,
#fc .fc-checkout .col-md-offset-3,
#fc .fc-container__grid--payment-method,
#fc .fc-checkout__additional-field--tos,
#fc .fc-checkout .fc-checkout__additional-field--secure-transfer,
#fc .fc-checkout .fc-checkout__additional-field--subscribe,
#fc .fc-checkout .fc-checkout__additional-field--save-account,
#fc .fc-checkout .fc-container__grid--checkout-subscription-cancel,
#fc .fc-checkout .fc-container__grid--checkout-subscription-cancel-helper-text,
#fc .fc-checkout .fc-container__grid--input--plastic-new-card-number {
    margin: 0 !important;
    width: 100% !important;
    @include breakpoint(large) {
        padding-left: 0 !important;
    }
}

#fc .fc-checkout .fc-form-group--multiple-inline > div:last-of-type {
    @include breakpoint(small) {
        padding-left: 15px !important;
    }
    @include breakpoint(large) {
        padding-left: 0 !important;
    }
}

#fc .fc-checkout .fc-container__grid--checkout-submit {
    margin-bottom: 4rem !important;
}

#fc .fc-checkout__section--payment .fc-checkout__section--payment__method__logo {
    margin: 10px 0 !important;
}

#fc .fc-alert-container--success .fc-alert--success .fc-input-group .fc-button--sign-in {
    height: 28px !important;
    margin-top: 0 !important;
    border-radius: 0 !important;
    font-family: $bodoni-sc-book !important;
    text-transform: lowercase !important;
    background: $light-gray;
    color: $black;
    text-shadow: none !important;
    @include -motion-ui-defaults;
    &:hover {
        color: $white;
        background: $black;
    }
}

#fc .fc-coupon-entry--input span.fc-input-group__button {
    position: absolute;
    right: 51px;
    font-size: 0;
    white-space: nowrap;
    z-index: 2;
}

#fc span.fc-input-group__button > .fc-button {
    height: 28px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    border-radius: 0 !important;
    font-family: $bodoni-sc-book !important;
    text-transform: lowercase !important;
    line-height: 0 !important;
    background: $white;
    color: $black;
    text-shadow: none !important;
    @include -motion-ui-defaults;
    &:hover {
        color: $white;
        background: $black;
        border: 1px solid $black;
    }
}

#fc .fc-coupon-entry__apply {
    left: 0 !important;
}

#fc .fc-subtotal--row,
#fc .fc-transaction table,
#fc .fc-transaction table tbody,
#fc .fc-transaction table {
    background-color: transparent !important;
    background: transparent !important;
}

#fc .fc-transaction__discounts .fc-subtotal--row .fc-subtotal__label {
    color: $black !important;
    @include breakpoint(medium) {
        width: inherit;
        max-width: inherit;
    }
    @include breakpoint(large) {
        width: 14rem;
        max-width: 14rem;
    }
}

#fc .fc-button:active:focus,
#fc .fc-button:focus {
    box-shadow: none !important;
}

#fc .fc-subtotal--row .fc-coupon__code {
    display: none !important;
}

#fc .fc-subtotal--row .fc-coupon__name,
#fc .fc-subtotal--row .fc-subtotal__value {
    font-family: $bodoni-sc-book !important;
}

#fc .fc-transaction__discounts .fc-subtotal--row .fc-subtotal__value {
    color: $bright-red !important;
}

#fc .fc-subtotal--row .fc-coupon__name {
    color: $dark-gray !important;
}

#fc .fc-subtotal--row .fc-action--close {
    // position: absolute !important;
    // top: 0 !important;
    // left: 0 !important;
    opacity: .5 !important;
}

#fc .fc-checkout div[data-fc-error-for="customer_password"] {
    background: transparent !important;
    margin-bottom: 15px !important;
    padding: 0 !important;
}

#fc .fc-checkout div[data-fc-error-for="customer_password"] p {
    color: $black !important;
    text-shadow: none !important;
}

#fc .fc-checkout .fc-password-actions.fc-list--inline {
    padding: 0 !important;
}

#fc .fc-checkout .fc-container__grid--plastic-new-exp-month,
#fc .fc-checkout .fc-container__grid--plastic-new-exp-year {
    width: 33.3333% !important;
}

#fc .fc-checkout .fc-container__grid--plastic-new-csc {
    width: 20% !important;
}

#fc .fc-checkout .fc-form-control--new-customer-password {
    border: none !important;
    background-color: #f2f2f2 !important;
}

#fc .fc-checkout .fc-container__grid--plastic-new-exp-month {
    padding-left: 0 !important;
    padding-right: 7px !important;
}

#fc .fc-checkout .fc-container__grid--plastic-new-exp-year {
    padding-left: 7px !important;
    padding-right: 7px !important;
}

#fc .fc-checkout .fc-container__grid--plastic-new-csc {
    padding-right: 0 !important;
    padding-left: 7px !important;
    float: right;
}

#fc .fc-checkout .fc-checkout__section--payment .fc-payment-method--plastic__new .fc-form-group:first-of-type {
    margin-top: 0 !important;
}

#fc .fc-checkout .fc-input-group-container,
#fc .fc-checkout .fc-container-checkout__grid--postal-code {
    padding: 0 !important;
}

#fc .fc-checkout .fc-button--submit {
    margin-top: 15px !important;
    border: 1px solid $medium-gray !important;
}

#fc .fc-checkout .fc-password-actions.fc-list--inline li {
    width: 100%;
    max-width: none !important;
}

#fc .fc-checkout .fc-password-actions.fc-list--inline li small,
#fc .fc-checkout .fc-container__grid--postal-code-errors {
    width: 100% !important;
}

#fc .fc-checkout section.fc-checkout__section--shipping-results {
    margin: 15px 0 !important;
}

#fc .fc-checkout .fc-cancel-continue-shopping .fc-button,
#fc .fc-checkout .fc-password-actions.fc-list--inline li button {
    @include foxy-reset-button-open;
    &:hover {
        text-decoration: none !important;
    }
}

#fc .fc-checkout .fc-input-group-container--active {
    background-color: transparent !important;
    border-radius: none !important;
    transition: none !important;
}

#fc .fc-checkout .fc-form-label--different-address {
    width: 100% !important;
    padding: 0 !important;
}

#fc .fc-checkout #use_different_addresses {
    float: none !important;
}

#fc .fc-checkout .fc-input-group-container--checkbox {
    width: 100% !important;
    padding: 0 !important;
    margin-bottom: 0 !important;
}

#fc .fc-checkout .fc-form-control--create-account {
    display: inline-block !important;
    float: none !important;
}

#fc .fc-checkout .fc-alert {
    padding: 5px !important;
    margin-top: 0 !important;
}

#fc .fc-checkout .fc-input-group {
    margin-bottom: 15px !important;
}

#fc .fc-checkout .fc-alert.fc-alert--success {
    margin-bottom: 5px !important;
}

#fc .fc-checkout div[data-fc-messaging-for="customer_email"] input#customer_password {
    border: none !important;
    background: $light-gray !important;
}

#fc .fc-checkout div[data-fc-messaging-for="customer_email"] input#customer_password:focus {
    margin: 0 !important;
    background: $light-gray !important;
    border: none !important;
}

// #fc .fc-checkout .fc-checkout__section--payment .fc-payment-method--plastic__new {
//     z-index: -1 !important;
// }
#fc .fc-checkout .fc-alert-container--error .fc-input-group,
#fc .fc-checkout .fc-alert-container--error .fc-container__grid--payment-method .fc-input-group-container--active,
#fc .fc-checkout .fc-alert-container--error .fc-input-group-container--checkbox,
#fc .fc-checkout .fc-alert-container--error .fc-input-group-container--active {
    border: none !important;
}

#fc .fc-checkout input#cc_cvv2_saved {
    margin-top: 5px !important;
    margin-bottom: 10px !important;
}

#fc .fc-checkout .fc-alert {
    background-color: $light-gray !important;
    font-family: $bodoni-book !important;
    font-size: 16px !important;
}

#fc .fc-checkout .fc-alert.fc-alert--danger>div,
#fc .fc-checkout .fc-alert--danger {
    background-color: $black !important;
    font-family: $bodoni-book !important;
    font-size: 16px !important;
    text-shadow: none !important;
}

// #fc [data-fc-error-notifier] {
//     position: relative !important;
// }

#fc .fc-checkout .fc-alert.fc-alert--danger[data-fc-error-notifier] h3 {
    font-family: $bodoni-book !important;
    font-size: 16px !important;
}

#fc .fc-checkout .fc-form-group .fc-input-group-container__title {
    background: transparent !important;
    border-radius: none !important;
    margin: 0 !important;
    padding: 0 !important;
}

#fc .fc-checkout .fc-container__grid--input--first-name,
#fc .fc-checkout .fc-container__grid--input--last-name,
#fc .fc-checkout .fc-container__grid--postal-code,
#fc .fc-checkout .fc-container__grid--postal-code-actions {
    width: 50% !important;
}

#fc .fc-checkout .fc-container__grid--input--address1,
#fc .fc-checkout .fc-container__grid--label--address2 {
    width: 100% !important;
}

#fc .fc-checkout .fc-form-group--multiple-inline > div:first-of-type,
#fc .fc-checkout .fc-container__grid--input--phone,
#fc .fc-checkout .fc-container__grid--label--address2,
#fc .fc-checkout .fc-form-control fc-form-control--city-options,
#fc .fc-checkout .fc-input-group-container--radios fc-input-group--full-page {
    @include breakpoint(large) {
        padding-left: 0 !important;
    }
    padding-right: 15px !important;
}

#fc .fc-checkout .fc-form-group {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
}

#fc .fc-checkout .fc-input-group-container__title.fc-form-label--plastic-saved.fc-form-label {
    display: block !important;
}

#fc .fc-checkout .fc-payment-method-plastic-saved.fc-payment-method--plastic-saved__card-number {
    font-family: $bodoni-book !important;
}

#fc .fc-checkout .fc-form-label--plastic-new__text {
    @include foxy-reset-anchor;
    left: initial !important;
    top: -3px !important;
    float: right !important;
    width: auto !important;
    padding: 1rem 0 !important;
}

#fc .fc-checkout label.fc-input-group-container__title.fc-form-label--plastic-saved.fc-form-label,
#fc .fc-checkout .fc-input-group-container.fc-input-group-container--radio.fc-input-group-container--active div.fc-form-group {
    border-bottom: $border-ultralight;
}

#fc .fc-checkout .fc-container__grid--static--plastic-saved-csc,
#fc .fc-checkout .fc-container__static--plastic-saved-expiration {
    padding: 0 !important;
    float: right !important;
}

#fc .fc-checkout .fc-container__static--plastic-saved-expiration p {
    text-align: right !important;
}

#fc .fc-checkout label.fc-form-label__plastic-saved-expiration.fc-form-label,
#fc .fc-checkout label.fc-form-label.fc-form-label--cvv2-saved {
    text-align: left !important;
    padding-left: 0px !important;
    width: auto !important;
    min-width: 126px !important;
}

#fc .fc-checkout section.fc-checkout__section--payment input[type="radio"].fc-form-control,
#fc .fc-checkout section.fc-checkout__section--payment input[type="radio"].fc-form-control:focus {
    width: auto !important;
    min-height: 1px !important;
    max-height: 14px !important;
    margin-left: -9999px !important;
}

#fc .fc-checkout input[type="radio"].fc-form-control--plastic-new--checkbox.fc-form-control,
#fc .fc-checkout .fc-input-group-container.fc-input-group-container--radio.fc-input-group-container--active input[type="radio"],
#fc .fc-checkout__section--payment .fc-checkout__section--payment__method__logo img {
    z-index: 3 !important;
}

//RECEIPT
#fc .fc-container {
    margin-top: 2rem;
}

#fc-receipt-container #fc-receipt__section--header,
#fc-receipt-container #fc-receipt__section--general-information,
#fc-receipt-container #fc-receipt__section--billing-information,
#fc-receipt-container #fc-receipt__section--shipping-information,
#fc-receipt-container #fc-cart--responsive .fc-cart__title,
#fc-receipt-container #fc-cart--responsive .fc-cart__main {
    display: none !important;
}

#fc-receipt-container {
    margin: 0 !important;
}

#fc-receipt-container #fc-messages,
#fc-receipt-container #fc-receipt__section--actions {
    @include breakpoint(large) {
        padding: 0 !important;
    }
}

#fc-receipt-container .fc-receipt__section {
    width: 100% !important;
}

#fc-receipt-container .fc-alert--success {
    padding: 0 !important;
}

#fc-receipt-container .fc-alert--success,
#fc-receipt-container>div {
    background: transparent !important;
    color: $dark-gray !important;
    font-family: $bodoni-book !important;
}

#fc-receipt-container>div {
    @include breakpoint(small) {
        font-size: 1.5rem;
        padding: 1rem;
    }
    @include breakpoint(medium) {
        font-size: 1.75rem;
    }
    @include breakpoint(large) {
        font-size: 2.15rem;
        padding: 0;
    }
}

#fc-receipt-container .fc-receipt-action.fc-receipt-action--continue {
    width: 100% !important;
    @include breakpoint(small) {
        text-align: center !important;
    }
    @include breakpoint(medium) {
        text-align: right !important;
    }
}

#fc-receipt-container .fc-button {
    @include foxy-reset-anchor;
    text-align: left !important;
    border: none !important;
    background: transparent !important;
    padding: 0 !important;
    text-shadow: none !important;
}

#fc-receipt-container .fc-receipt-action--print {
    display: none !important;
}

#fc-receipt__section--receipt_messaging_and_actions {
    border-bottom: none !important;
}

//IMAGES

#fc .logo-inner-wrapper {

}
#fc .checkout-image {
    max-width: 940px !important;
    @include breakpoint(small) {
        margin: 0 auto !important;
        margin-top: 1.5rem !important;
        margin-bottom: 2rem !important;
    }
    @include breakpoint(large) {
        margin: 0 auto !important;
        margin-top: 2.5rem !important;
    }
}

#fc .checkout-image img {
    width: 100% !important;
    @include breakpoint(small) {
        padding-top: 5.5rem !important;
    }
    @include breakpoint(large) {
        padding-top: 10rem !important;
    }
}

// #fc .fc-checkout .fc-container {
//     @include breakpoint(small) {
//         padding-top: 450px !important;
//         background: url('http://www.jujuskin.com/assets/img/juju_flower.png') top left no-repeat transparent;
//         background-size: none;
//     }
//     @include breakpoint(medium) {
//         padding-top: 550px !important;
//         background-size: contain;
//     }
//     @include breakpoint(large) {
//         padding-top: 650px !important;
//     }
// }
// #fc #fc-receipt-container {
//     @include breakpoint(small) {
//         padding-top: 450px !important;
//         background: url('http://www.jujuskin.com/assets/img/juju_nut.jpg') top left no-repeat transparent;
//         background-size: none;
//     }
//     @include breakpoint(medium) {
//         padding-top: 550px !important;
//         background-size: contain;
//     }
//     @include breakpoint(large) {
//         padding-top: 650px !important;
//     }
// }


#fc blockquote {
    padding: initial !important;
    margin: initial !important;
    font-size: initial !important;
    border-left: none !important;
}

#fc blockquote span {
    display: inline-block;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    font-family: $bodoni-sc-book !important;
    @include small-header;
    @include breakpoint(small) {
        margin-bottom: .5rem !important;
        padding: .5rem 0 !important;
    }
    @include breakpoint(medium) {
        margin-bottom: .5rem !important;
        padding: 1rem 0 !important;
    }
    @include breakpoint(large) {
        margin-bottom: 0 !important;
    }
}

#fc a.home {
    position: absolute;
    left: 50%;
    z-index: 1002;
    @include breakpoint (small) {
        margin-left: -32px !important;
        margin-top: 0 !important;
    }
    @include breakpoint (large) {
        margin-left: -86.265px !important;
        margin-top: 1rem !important;
    }
}

#fc .logo-inner-wrapper {
    height: 0;
    margin: 0 auto !important;
    position: relative !important;
    @include breakpoint(small) {
        width: 3.75rem;
        padding-top: 3.75rem;
    }
    @include breakpoint(large) {
        width: 5rem;
        padding-top: 5rem;
    }
}

// #fc .base-info blockquote p, 
// #fc .base-info blockquote span {
//     color: #97a4a9 !important;
// }

#fc .base-info a {
    display: inline-block;
    margin-right: .5rem !important;
    border-bottom: 1px solid transparent;
    @include -motion-ui-defaults;
    &:hover {
        border-bottom: 1px solid $black;
    }
}

#fc .footer {
    opacity: 1 !important;
}

#fc .footer .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//INTRO/QUOTE BLOCKS
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
#first {
    // background: lighten($juju-caramel, 10%);
    background: $blue-ash-light;
}

#first .header-logo {
    position: relative;
    width: 100%;
    z-index: 100;
}

#first .quote-block {
    padding: 4rem 0;
    position: relative;
}

#first .quote-block p {
    @include -motion-ui-defaults;
    @include breakpoint(small) {
        font-size: 1.5rem;
    }
    @include breakpoint(medium) {
        font-size: 2rem;
    }
    @include breakpoint(large) {
        font-size: 2.5rem;
    }
    @include breakpoint(xlarge) {
        font-size: 3.25rem;
    }
}

#second .quote-block {
    padding: 4rem 0;
    position: relative;
    background: $green-ash-light;
    // background: lighten($red-ash, 10%);
    @include -motion-ui-defaults;
}

#first .quote-block p,
#first .quote-block blockquote span,
#second .quote-block p,
#second .quote-block blockquote span {
    color: $black !important;
    @include -motion-ui-defaults;
}

#second .quote-block p {
    // color: lighten($red-ash, 30%) !important;
    @include -motion-ui-defaults;
    @include breakpoint(small) {
        font-size: 1.5rem;
    }
    @include breakpoint(medium) {
        font-size: 2rem;
    }
    @include breakpoint(large) {
        font-size: 2.5rem;
    }
    @include breakpoint(xlarge) {
        font-size: 3.25rem;
    }
}

section#first[Q1Animate="0"] .quote-block p,
section#first[Q1Animate="0"] .quote-block h2,
section#third[Q2Animate="0"] .quote-block p,
section#third[Q2Animate="0"] .quote-block h2 {
    transition-delay: .5s;
    opacity: 0;
    transform: translate3d(0, 4rem, 4rem);
}

section#first[Q1Animate="1"] .quote-block p,
section#first[Q1Animate="1"] .quote-block h2,
section#first[Q2Animate="1"] .quote-block p,
section#first[Q2Animate="1"] .quote-block h2 {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

section#first[Q1Animate="2"] .quote-block p,
section#first[Q1Animate="2"] .quote-block h2,
section#first[Q2Animate="2"] .quote-block p,
section#first[Q2Animate="2"] .quote-block h2 {
    opacity: 0;
    transform: translate3d(0, -2rem, -2rem);
}

section#first[Q1Animate="0"] .quote-block blockquote span,
section#first[Q2Animate="0"] .quote-block blockquote span {
    transition-delay: 1s;
    opacity: 0;
    transform: translate3d(0, 4rem, 4rem);
}

section#first[Q1Animate="1"] .quote-block blockquote span,
section#first[Q2Animate="1"] .quote-block blockquote span {
    opacity: 0;
    transform: translate3d(0, 0, 0);
}

section#first[Q1Animate="2"] .quote-block blockquote span,
section#first[Q2Animate="2"] .quote-block blockquote span {
    opacity: 0;
    transform: translate3d(0, -2rem, -2rem);
}

#glossary-intro.dropcap p:first-child:first-letter {
    color: darken($juju-red, 40%);
    font-family: $bodoni-book;
    @include breakpoint(small) {
        float: none;
        padding-right: 0;
        line-height: 40px;
        font-size: 45px;
    }
    @include breakpoint(medium) {
        line-height: 55px;
        margin-right: -9px;
        font-size: 80px;
    }
    @include breakpoint(large) {
        float: left;
        padding-right: 23px;
        margin-right: -50px;
        line-height: 20px;
        font-size: 120px;
    }
    @include breakpoint(xlarge) {
        line-height: 45px;
        font-size: 200px;
        padding-right: 0;
        margin-right: -80px;
    }
}

#introblock p:before {
    box-sizing: content-box !important;
    position: absolute;
    top: 0;
    left: 50%;
    width: 30px;
    height: 1px;
    border-top: 1px solid;
}

#introblock p:after {
    position: absolute;
    top: 0;
    left: 50%;
    width: 30px;
    height: 1px;
    border-top: 1px solid;
}

#mobileProductSlider {
    overflow: hidden;
    width: 100% !important;
}

blockquote {
    @include -motion-ui-defaults;
}

blockquote[Animate="0"] {
    @include breakpoint(medium) {
        opacity: 0;
        @include -motion-ui-defaults;
    }
}

blockquote[Animate="1"] {
    @include breakpoint(medium) {
        opacity: 1;
    }
}

blockquote[Animate="2"] {
    @include breakpoint(medium) {
        opacity: 1;
    }
}

#first .quote-block>.columns,
#second .quote-block>.columns {
    position: relative;
    height: 100%;
    display: block;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//PRODUCT BLOCK
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
#section2 {
    background-color: $white;
}

section#products .product {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//PRODUCT SLIDER (ROYALSLIDER)
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


.rsDefault,
.rsDefault .rsOverflow,
.rsDefault .rsSlide,
.rsDefault .rsVideoFrameHolder,
.rsDefault .rsThumbs {
    background: $white !important;
}

.rsArrowIcn {
    @include -motion-ui-defaults;
}

.visibleNearby {
    width: 100%;
    background: transparent;
    color: $juju-paper;
}

#product-caption {
    background-color: $white;
}

.rsDefault .rsArrow {
    width: 25% !important;
}

// section#products .rsGCaption span.product-title.family-color~.rsABlock {
//     color: $warm-ash;
// }
section#products .rsCaption {
    width: auto;
    @include -motion-ui-defaults;
}

section#products .rsCaption[Animate="0"] {
    @include breakpoint(medium) {
        opacity: 0;
        transition-delay: 1s;
        transform: translate3d(0, 2rem, 2rem);
    }
}

section#products .rsCaption[Animate="1"] {
    @include breakpoint(medium) {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.rsSlide {
    opacity: 0;
    @include -motion-ui-defaults;
}

.rsSlide.rsActiveSlide {
    opacity: 1;
}

.rsActiveSlide .rsLink {
    display: block !important;
    cursor: url(../img/link-cursor.png), auto;
}


/* Non-linear resizing on smaller screens */

@media screen and (min-width: 0px) and (max-width: 900px) {
    #gallery-1 {
        padding: 12px 0 12px;
    }
    #gallery-1 .rsOverflow,
    .royalSlider#gallery-1 {
        height: 400px !important;
    }
}

@media screen and (min-width: 0px) and (max-width: 500px) {
    #gallery-1 .rsOverflow,
    .royalSlider#gallery-1 {
        height: 300px !important;
    }
}

.product-select {
    width: 18rem;
    border: 1px solid $dark-gray;
    right: 0;
    padding: 0;
    margin: 0;
    outline: none;
    position: absolute;
    z-index: 1000;
}

.product-select:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: inset 4px;
    border-color: $medium-gray transparent transparent;
    border-top-style: solid;
    position: absolute;
    top: 0.5rem;
    right: 5px;
}

//PRODUCT SELECTOR
.product-select ul {
    margin: 0;
    padding: .5rem 0;
    border-top: 1px solid $dark-gray;
}

.product-select ul li {
    padding: .25rem .5rem;
    font-size: 11px;
    color: $dark-gray;
}

.product-select ul li:hover {
    color: $black;
}

.product-select label {
    padding: 0 .5rem;
    font-size: 11px;
    color: $black;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//PRODUCT SLIDER (SLICK)
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.slider-prev,
.slider-next {
    position: absolute;
    height: 100%;
    cursor: pointer;
    opacity: .6;
    border-radius: 2px;
    z-index: 21;
    @include breakpoint(small) {
        width: 3rem;
    }
    @include breakpoint(large) {
        width: 7rem;
    }
}

.slider-prev:hover,
.slider-next:hover {
    opacity: .95;
}

.slider-prev {
    top: 0; 
    left: 0;
}

.slider-prev {
    background: url(../img/arrow-left.png);
    background-repeat: no-repeat;
    @include breakpoint(small) {
        background-position: 20px 48.8813760%;
    }
    @include breakpoint(large) {
        background-position: 54px 48.8813760%;
    }
}

.slider-next {
    right: 0; 
    top:0;
}

.slider-next {
    background: url(../img/arrow-right.png);
    background-repeat: no-repeat;
    @include breakpoint(small) { 
        background-position: -20px 48.8813760%;
    }
    @include breakpoint(large) { 
        background-position: 9px 48.8813760%;
    }
}

.image-ratio\:5x4 > div {
    padding-top: 125% !important;
}

.image-ratio\:17x10 > div {
    padding-top: 58.8% !important;
}

.overlay-container {
    position: relative;
    padding-bottom: 1px;
}

section#products {
    @include breakpoint(small) { 
        background: $ultralight;
        padding: 2rem 0 0;
    }
    @include breakpoint(medium) { 
        background: $ultralight;
        padding: 2rem 0;
    }
    @include breakpoint(large) {
        background: $white;
        padding: 0;
    }
}

#products-header {
    @include breakpoint(small) { 
        position: absolute;
        padding: 0;
        background: transparent;
    }
    @include breakpoint(medium) {
        position: relative;
    }
    @include breakpoint(large) { 
        border-top: none;
    }
}

#productSlider {
    @include breakpoint(small) {
        padding: 4rem 0 0;
    }
    @include breakpoint(medium) {
        padding: 2rem 0;
    }
    @include breakpoint(large) {
        padding: 6rem 0;
    }
}

#productSlider #slider-nav {
    position: absolute;
    top: 0;
    bottom: 0;
    left:0;
    right: 0;
    height: 100%;
    width: 100%;
}

.product-thumb:hover .product-caption {
    // @include breakpoint(small) {
    //     background-color: transparent;
    // }
    @include breakpoint(large) {
        opacity: 1;
        background-color: rgba(0,0,0,.84);
    }
}

.product-thumb {
    @include breakpoint(small) {
        margin-bottom: 1rem;
    }
    @include breakpoint(medium) {
        margin-bottom: 2rem;
    }
    @include breakpoint(large) {
        margin-bottom: 0;
    }
}

.product-thumb .product-caption {   
    background-color: $white;
    text-align: left;
    transition: all 300ms ease-out;
    @include breakpoint(small only) {
        padding: 1rem !important;
        padding-top: 1rem !important;
    }
    @include breakpoint(medium only) {
        padding: 1rem !important;
        padding-top: 1rem !important;
    }
    @include breakpoint(small) {
        position: relative;
        background: $white;
        min-height: auto;
    }
    @include breakpoint(medium) {
        position: relative;
        min-height: 185px;
    }
    @include breakpoint(large) {
        position: absolute;
        padding-top: inherit;
        opacity: 0;
        background: transparent;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        min-height: initial;
    }
}

.product-thumb .product-caption p {
    margin-bottom: 0;
    height: 100%;
    width: 100%;
    @include breakpoint(small) {
        position: relative;
        padding: 0;
        text-align: center;
        font-size: 1rem;
        color: $dark-gray;
    }
    @include breakpoint(large) {
        position: absolute;
        padding: 1.5rem;
        margin-left: -1.5px;
        bottom: 0;
        top: 0;
        text-align: left;
        color: $white;
    }
    @include breakpoint(xlarge) {
        position: absolute;
        padding: 2rem;
        margin-left: -1.5px;
        bottom: 0;
        top: 0;
        text-align: left;
        font-size: 1.2rem;
        color: $white;
    }
}

.product-thumb .product-caption p span.product-tagline {
    @include breakpoint(small) {
        color: $dark-gray !important;
        display: block;
    }
    @include breakpoint(large) {
        color: $white !important;
        display: initial;
    }
}

.product-thumb .product-caption p span.product-description {
    @include breakpoint(small) {
        color: $dark-gray !important;
        display: block;
    }
    @include breakpoint(large) {
        color: $white !important;
        display: initial;
    }
}

.product-thumb span.product-description {
    width: 100%;
    bottom: 0;
    left: 0;
    @include breakpoint(small) {
        position: relative;
        padding: 0;
    }
    @include breakpoint(large) {
        position: absolute;
        padding: 1.5rem;
    }
    @include breakpoint(xlarge) {
        position: absolute;
        padding: 2rem;
    }
}

.defer-image img {
    display: block;
    min-width: 100%;
    max-width: 100%;
    @include -motion-ui-defaults;
}

.defer-image:after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    background: $ultralight;
    border-radius: 50%;
    animation: bobble 1s cubic-bezier(0.6, 1, 1, 1) infinite;
    z-index: -1;
    @include breakpoint(small) {
        width: 5rem;
        height: 5rem;
        margin: -2.5rem 0 0 -2.5rem;
    }
    @include breakpoint(large) {
        width: 10rem;
        height: 10rem;
        margin: -5rem 0 0 -5rem;
    }
}

.defer-image.is-loading img {
    opacity: 0;
    @include -motion-ui-defaults;
}

.defer-image.is-loaded img {
    animation: fadeIn .3s both;
}


.product-title.cleanser-color p {
    // color: darken($juju-red, 15%);
    @include breakpoint(small) {
        color: $dark-gray;
    }
    @include breakpoint(large) {
        color: $juju-red !important;
    }
}

// section#products .rsGCaption span.product-title.cleanser-color~.rsABlock {
//     color: $juju-red;
// }
.product-title.toner-color p {
    // color: darken($juju-green, 15%);
    @include breakpoint(small) {
        color: $dark-gray;
    }
    @include breakpoint(large) {
        color: $juju-green !important;
    }
}
// section#products .rsGCaption span.product-title.toner-color~.rsABlock {
//     color: $juju-green;
// }
.product-title.cream-color p {
    // color: darken($juju-violet, 15%);
    @include breakpoint(small) {
        color: $dark-gray;
    }
    @include breakpoint(large) {
        color: $juju-violet !important;
    }
}

// section#products .rsGCaption span.product-title.cream-color~.rsABlock {
//     color: $juju-violet;
// }
.product-title.scrub-color p {
    // color: darken($juju-yellow, 15%);
    @include breakpoint(small) {
        color: $dark-gray;
    }
    @include breakpoint(large) {
        color: $juju-yellow !important;
    }
}

// section#products .rsGCaption span.product-title.scrub-color~.rsABlock {
//     color: $juju-yellow;
// }
.product-title.eye-serum-color p {
    // color: darken($juju-blue, 15%);
    @include breakpoint(small) {
        color: $dark-gray;
    }
    @include breakpoint(large) {
        color: $juju-blue !important;
    }
}

// section#products .rsGCaption span.product-title.eye-serum-color~.rsABlock {
//     color: $juju-blue;
// }
.product-title.dry-serum-color p {
    // color: darken($juju-orange, 15%);
    @include breakpoint(small) {
        color: $dark-gray;
    }
    @include breakpoint(large) {
        color: $juju-orange !important;
    }
}

// section#products .rsGCaption span.product-title.dry-serum-color~.rsABlock {
//     color: $juju-orange;
// }
.product-title.family-color p {
    @include breakpoint(small) {
        color: $dark-gray;
    }
    @include breakpoint(large) {
        color: $juju-caramel !important;
    }
}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//PRODUCT PAGE
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.row.full {
    min-width: 100% !important;
    max-width: none !important;
}

.product-page-content {
    overflow-y: scroll !important;
}

.pp-content .defer-image {
    @include breakpoint(small) {
        width: 100%;
    }
    @include breakpoint(large) {
        max-width: 60rem;
    }
    @include breakpoint(xlarge) {
        max-width: 70rem;
    }
    @include breakpoint(xxlarge) {
        max-width: 90rem;
    }
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.shopping-item {
    position: relative;
    width: 100%;
    background: $white;
    @include breakpoint(small) {
        height: auto;
    }
    @include breakpoint(large) {
        height: 100%;
    }
}

.pp-content .vertical-center.reflow-mobile {
    width: 100%;
    @include breakpoint(small) {
        padding-bottom: 1rem;
        border-bottom: 1px solid $ultralight;
    }
    @include breakpoint(medium) {
        padding-bottom: 0;
        border-bottom: none;
    }
}

// .shopping-item img {
//     @include breakpoint(small) {
//         padding: 2rem;
//     }
//     @include breakpoint(medium) {
//         max-height: 400px;
//         padding: 3rem auto 60px auto;
//     }
//     @include breakpoint(large) {
//         max-height: 600px;
//         padding: 4% auto 80px auto;
//     }
//     @include breakpoint(xlarge) {
//         max-height: 600px;
//         padding: 4% auto 80px auto;
//     }
//     @include breakpoint(xxlarge) {
//         max-height: 750px;
//         padding: 5% auto 60px auto;
//     }
//     @include -motion-ui-defaults;
// }
.sidebar {
    display: inline-block;
    padding: 0 3rem;
    background-color: transparent;
    overflow-y: scroll;
    @include breakpoint(small) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
    @include breakpoint(large) {
        position: absolute;
        right: 0;
        width: 24rem;
        padding-left: 3rem;
        padding-right: 3rem;
    }
    @include breakpoint(xxlarge) {
        width: 27rem;
    }
}


.sidebar-content {
    margin-bottom: 4rem;
    @include breakpoint(small) {
        margin-top: 1rem;
        background-color: $white;
    }
    @include breakpoint(medium) {
        margin-top: 2rem;
        background-color: transparent;
    }
    @include breakpoint(large) {
        margin-top: 3.5rem;
    }
    position: relative;
}

.sidebar-content h1 {
    font-family: $bodoni-book;
}

.sidebar-content h1.product-name {
    font-size: 21px;
}

.sidebar-content h2 {
    text-transform: lowercase;
    letter-spacing: $standard-kerning;
}

.sidebar-content h2.price {
    letter-spacing: inherit;
}

.sidebar-content h1,
.sidebar-content h2 {
    font-size: 15px;
    margin: 0;
}

.sidebar-content ul,
.sidebar-content li,
.sidebar-content li a {
    margin: 0;
}

.sidebar-content .button {
    margin: 1rem 0 0;
}

.sidebar-content {
    text-align: left;
}

.sidebar-content .product-summary {
    padding-top: 2rem;
}

.add-to-cart input.button.open:focus,
.add-to-cart input.button.open:hover {
    color: $white !important;
}

[type='number'],
[type='number']:focus {
    display: inline-block;
    width: 4rem;
    border: none !important;
    box-shadow: none;
    margin: 0;
    padding: 0 0 0 .5rem;
}

[type='text'] {
    padding: 0 !important;
    color: $black !important;
    border: none !important;
    box-shadow: none;
    margin: 0;
    padding: 0;
}

label {
    font-family: $bodoni-sc-book;
    font-size: 15px;
    text-transform: lowercase;
}

.more-info .accordion-title {
    border: none !important;
    border-bottom: none;
    background: transparent;
}

.accordion-title:hover,
.accordion-title:focus {
    background: transparent !important;
}

.is-accordion-submenu-parent[aria-expanded="true"] .accordion-title::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    transition: $single-transition;
}

.accordion-title::before {
    right: 0;
    font-family: $bodoni-book;
    font-size: 22px;
    text-align: center;
    -webkit-transform-origin: 50% 50% !important;
    -ms-transform-origin: 50% 50% !important;
    transform-origin: 50% 50% !important;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    transition: $single-transition;
}

.is-accordion-submenu-parent > a::after {
    display: none !important;
}

// .accordion-title:after {
//     content: "+";
//     font: $bodoni-sc-book;
//     font-size: 1rem;
//     position: absolute;
//     right: 0;
//     top: 0;
//     color: $dark-gray;
//     -webkit-transform: rotate(45deg);
//     -moz-transform: rotate(45deg);
//     -o-transform: rotate(45deg);
//     -ms-transform: rotate(45deg);
//     transform: rotate(45deg);
//     transition: $single-transition;
// }
.sidebar .more-info {
    margin-bottom: .5rem;
}

.more-info li a {
    color: $medium-gray;
}

.sidebar .menu {
    margin: 0;
}

.sidebar .menu a,
.sidebar #related a {
    @include anchor;
    text-transform: lowercase;
    padding: .25rem 0;
    transition: $single-transition;
}

// .sidebar .menu a:hover,
// .sidebar .menu a:active,
// .sidebar #related a:hover,
// .sidebar #related a:active {
//     color: darken($juju-red, 30%);
// }
.sidebar #related a,
.sidebar #related a {
    font-family: $bodoni-book;
    text-transform: none;
    letter-spacing: initial;
}

.sidebar .add-to-cart {
    margin: .5rem 0;
}

form#add-to-cart-form {}

.sidebar span.badge.cleanser-badge,
.sidebar span.badge.toner-badge,
.sidebar span.badge.cream-badge,
.sidebar span.badge.scrub-badge,
.sidebar span.badge.eye-serum-badge,
.sidebar span.badge.dry-serum-badge,
.sidebar span.badge.family-badge {
    min-width: 10px;
    min-height: 10px;
    margin-right: .5rem;
}

.sidebar span.badge.cleanser-badge {
    background: $juju-red;
}

.sidebar span.badge.toner-badge {
    background: $juju-green;
}

.sidebar span.badge.scrub-badge {
    background: $juju-yellow;
}

.sidebar span.badge.cream-badge {
    background: $juju-violet;
}

.sidebar span.badge.eye-serum-badge {
    background: $juju-blue;
}

.sidebar span.badge.dry-serum-badge {
    background: $juju-orange;
}

.sidebar span.badge.family-badge {
    background: $warm-ash;
}

.sidebar .related-link {
    @include anchor;
    font-family: $bodoni-book !important;
    letter-spacing: initial !important;
}

.pullquote {
    @include breakpoint(small) {
        display: none;
    }
    @include breakpoint(medium) {
        display: block;
        position: relative;
        width: 100%;
    }
    @include breakpoint(large) {
        position: absolute;
        max-width: 20rem;
        left: 0;
        bottom: 80px;
    }
    // border: $border-light;
    // background-color: darken($warm-ash,25%);
}

// .pullquote:after {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: $white;
//     opacity: .3;
// }
.pp-family .pullquote {
    @include breakpoint(medium) {
        left: 0;
    }
    @include breakpoint(large) {
        left: 1.5rem;
        top: 20%;
    }
}

.pullquote blockquote {
    padding: 0 3rem !important;
}

blockquote p,
blockquote span {
    font-family: $bodoni-book;
    color: #A4A4A4 !important;
    line-height: initial;
}

blockquote p {
    text-align: center;
    margin-bottom: 0;
    @include breakpoint(medium) {
        font-size: 18px;
    }
    @include breakpoint(large) {
        font-size: 20px;
    }
    @include breakpoint(xlarge) {
        font-size: 18px;
    }
}

blockquote {
    @include breakpoint(medium) {
        margin-bottom: .5rem;
    }
    border: none;
    text-align: center;
}

blockquote span {
    display: inline-block;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    font-family: $bodoni-sc-book;
    @include small-header;
    @include breakpoint(small) {
        margin-bottom: .5rem;
        padding: .5rem 0;
    }
    @include breakpoint(medium) {
        margin-bottom: .5rem;
        padding: 1rem 0;
    }
    @include breakpoint(large) {
        margin-bottom: 0;
    }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//STORY BLOCK
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.skrollr-body {
    width: 100%;
    height: 100%;
    @include breakpoint(small) {
        min-height: 1px;
    }
    @include breakpoint(large) {
        min-height: auto;
    }
}

.section-header {
    color: darken($book, 45%);
    background-color: $book;
    margin: 0;
    @include -motion-ui-defaults;
}

section#third {
    position: relative;
    width: 100%;
    @include breakpoint(medium) {
        padding: 1rem;
    }
    @include breakpoint(large) {
        padding: 0 2rem;
    }
    @include breakpoint(xlarge) {
        padding: 0 .5rem;
    }
    @include breakpoint(xxlarge) {
        padding: 0 4.5rem;
    }
    .hsContainer {
        display: table;
        table-layout: fixed;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
    }
    .hsContent {
        max-width: 450px;
        margin: -150px auto 0 auto;
        display: table-cell;
        vertical-align: middle;
        color: #ebebeb;
        padding: 0 8%;
        text-align: center
    }
}

section#third,
#third #story-header,
#third #story-header.section-header[Animate="2"] {
    background: $alt-brown;
}

#third #story-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

#third:after {
    content: "";
    display: inline-block;
    position: absolute;
    left: 50%;
    background: url(../img/_story/flower2.svg) no-repeat top left;
    background-size: contain;
    opacity: .3;
    @include breakpoint(small) {
        top: 100px;
        margin-left: -15px;
        width: 30px;
        height: 30px;
    }
    @include breakpoint(medium) {
        top: 110px;
    }
    @include breakpoint(large) {
        top: 260px;
        margin-left: -16.5px;
        width: 35px;
        height: 35px;
    }
}

#third .section-wrapper {
    @include breakpoint(small) {
        margin-top: 10rem;
    }
    @include breakpoint(large) {
        margin-top: 0;
    }
}

.spacer {
    content: '';
    position: relative;
    float: none;
    width: 100%;
    @include breakpoint(small) {
        display: none;
    }
    @include breakpoint(large) {
        display: block;
        height: 28rem;
    }
}

#book-left,
#book-right {
    @include breakpoint(small) {
        padding: 0;
    }
    @include breakpoint(large) {
        padding: inherit;
    }
}

.cell span.caption {
    display: block;
    font-family: $bodoni-sc-book;
    font-size: 15px;
    letter-spacing: 2px;
    text-align: center;
    text-transform: lowercase;
    color: $white;
    @include -motion-ui-defaults;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    @include breakpoint(small) {
        padding: 1rem 0;
        line-height: 15px;
    }
    @include breakpoint(large) {
        padding: 1.25rem 0;
    }
}

#third p:before,
#third p:after {
    content: "";
    position: absolute;
    height: 2px;
    width: 200px;
    left: 50%;
    margin-left: -100px;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
    @include -motion-ui-defaults;
}

#third p:before {
    top: -30px;
    border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
}

#third p:after {
    bottom: -30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

#third .columns .cell {
    float: left;
    @include breakpoint(small) {
        padding: 0 1rem;
        margin-bottom: 2rem;
    }
    @include breakpoint(large) {
        padding: 0 1rem;
        margin-bottom: 8rem;
    }
    @include breakpoint(xlarge) {
        padding: 0 2.5rem;
    }
}

#third .columns .cell.right {
    float: right !important;
}

// #third .columns .cell:nth-child(even) {
//     float: left;
// }
#third[Animate="0"] {
    @include breakpoint(medium) {
        opacity: 0;
    }
}

#third[Animate="1"] {
    @include breakpoint(medium) {
        opacity: 1;
    }
}

#products .section-header[Animate="0"] {
    @include breakpoint(medium) {
        opacity: 0;
        transform: translate3d(0, 2rem, 2rem);
    }
}

#products .section-header[Animate="1"] {
    @include breakpoint(medium) {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

#third #story-header.section-header[Animate="0"] {
    @include breakpoint(medium) {
        opacity: 0;
        position: relative;
        transform: translate3d(0, 2rem, 2rem);
    }
}

#third #story-header.section-header[Animate="1"] {
    @include breakpoint(medium) {
        opacity: 1;
        position: relative;
        transform: translate3d(0, 0, 0);
    }
}

#third #story-header.section-header[Animate="2"] {
    @include breakpoint(medium) {
        opacity: 1;
        font-size: 15px;
        color: $white;
        position: fixed;
        padding: 3.25rem 0;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .2);
        top: 0;
        left: 0;
        z-index: 20;
    }
}

#third .cell {
    @include breakpoint(large) {
        margin-bottom: 6rem;
    }
    @include breakpoint(xlarge) {
        margin-bottom: 8rem;
    }
    @include -motion-ui-defaults;
}

#third .cell[Animate="0"] {
    @include breakpoint(medium) {
        opacity: 0;
        transform: translate3d(0, 3rem, 3rem);
    }
}

#third .cell[Animate="1"] {
    @include breakpoint(medium) {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

#third .cell[Animate="2"] {
    @include breakpoint(medium) {
        opacity: 0;
    }
}

#third .cell[Animate="0"] p.dropcap:before,
#third .cell[Animate="0"] p.dropcap:after {
    @include breakpoint(medium) {
        -webkit-transform: scaleX(0);
        -moz-transform: scaleX(0);
        -ms-transform: scaleX(0);
        -o-transform: scaleX(0);
        transform: scaleX(0);
    }
}

#third .cell[Animate="1"] p.dropcap:before,
#third .cell[Animate="1"] p.dropcap:after {
    @include breakpoint(medium) {
        -webkit-transform: scaleX(1);
        -moz-transform: scaleX(1);
        -ms-transform: scaleX(1);
        -o-transform: scaleX(1);
        transform: scaleX(1);
    }
}

#third p {
    @include breakpoint(small) {
        font-size: 16px;
    }
    @include breakpoint(xlarge) {
        font-size: 20px;
    }
    @include breakpoint(xxlarge) {
        font-size: 25px;
    }
}

#third p,
span.caption {
    color: $white;
}

#third p.dropcap {
    position: relative;
    display: block;
    width: 100%;
}

#third p.dropcap:first-child:first-letter {
    color: $juju-clay;
    font-family: $bodoni-book;
    @include breakpoint(small) {
        float: left !important;
        padding-right: 10px !important;
        line-height: 50px;
        font-size: 55px;
    }
    @include breakpoint(xlarge) {
        line-height: 41px;
        font-size: 100px;
    }
    @include breakpoint(xxlarge) {
        line-height: 61px;
        font-size: 140px;
    }
}

#third .image-object {
    height: 0;
    margin: 0 auto;
    position: absolute;
}

#third .image-object svg {
    position: absolute;
    top: 0;
    left: 0;
    @include -motion-ui-defaults;
}

#third .image-object.first svg path {
    fill: $juju-light-green;
    @include -motion-ui-defaults;
}

#third .image-object.second svg path {
    fill: $juju-light-green;
    @include -motion-ui-defaults;
}

#third .image-object.third svg path {
    fill: $juju-light-green;
    @include -motion-ui-defaults;
}

#third .image-object.first {
    top: 20%;
    left: 1rem;
    @include breakpoint(small) {
        width: 4rem;
        padding-top: 4rem;
    }
    @include breakpoint(medium) {
        width: 7rem;
        padding-top: 7rem;
    }
    @include breakpoint(large) {
        width: 10rem;
        padding-top: 10rem;
    }
    @include breakpoint(xlarge) {
        width: 30rem;
        padding-top: 30rem;
    }
}

#third .image-object.second {
    top: 35%;
    right: 45%;
    @include breakpoint(small) {
        width: 8rem;
        padding-top: 8rem;
    }
    @include breakpoint(medium) {
        width: 12rem;
        padding-top: 12rem;
    }
    @include breakpoint(large) {
        width: 17rem;
        padding-top: 17rem;
    }
    @include breakpoint(xlarge) {
        width: 30rem;
        padding-top: 30rem;
    }
}

#third .image-object.third {
    top: 70%;
    left: 50%;
    @include breakpoint(small) {
        width: 8rem;
        padding-top: 8rem;
    }
    @include breakpoint(medium) {
        width: 9rem;
        padding-top: 9rem;
    }
    @include breakpoint(large) {
        width: 10rem;
        padding-top: 10rem;
    }
    @include breakpoint(xlarge) {
        width: 30rem;
        padding-top: 30rem;
    }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//CHECKOUT
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
#checkout-forms,
#cart-forms {
    padding-top: 5rem;
}

.bulletin {
    @include breakpoint(small) {
        font-size: 2rem;
        line-height: 2rem;
    }
    @include breakpoint(medium) {
        font-size: 2.25rem;
        line-height: 2.1rem;
    }
    @include breakpoint(large) {
        font-size: 2.75rem;
        line-height: 2.65rem;
    }
    @include breakpoint(xlarge) {
        font-size: 3rem;
        line-height: 2.8rem;
    }
    text-align: center;
    color: darken($dark-gray,
    5%);
    padding: 35% 4rem 0 4rem;
}

#simplecart td {
    font-size: 15px;
    font-family: $bodoni-book;
    font-weight: normal !important;
}

#simplecart th {
    font-size: 15px;
    font-family: $bodoni-sc-book;
    font-weight: normal !important;
    text-align: left;
    letter-spacing: $standard-kerning;
}

#simplecart table tbody {
    border: none !important;
}

#simplecart table tbody td,
#simplecart table tbody tr.heading th {
    @include breakpoint(small) {
        padding-left: .5rem;
        padding-right: .5rem;
    }
    @include breakpoint(medium) {
        padding-left: .25rem;
        padding-right: .25rem;
    }
    @include breakpoint(large) {
        padding-left: 0;
        padding-right: 0;
    }
    @include breakpoint(xlarge) {
        padding-left: 0;
        padding-right: 0;
    }
}

#simplecart table tbody input {
    margin: auto;
}

#simplecart table tbody tr {
    background-color: $white !important;
    border-bottom: $border-light;
}

#simplecart input.cart-update {
    border: none;
    background: $white;
    text-transform: lowercase;
    padding: 0;
    margin-bottom: .5rem;
    @include anchor;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//GLOSSARY
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.gp-content {
    background-color: $white;
}

.gp-content .lead {
    @include breakpoint(small) {
        margin-top: rem; 
    }
    @include breakpoint(large) {
        margin-top: 4rem; 
    }
}

.gp-content .right {
    @include breakpoint(large) {
        margin-top: 5rem;
    }
}

#glossary-title {
    text-align: center;
    @include breakpoint(small) {
        margin-bottom: 2rem;
        margin-top: 6rem;
    }
    @include breakpoint(medium) {
        margin-bottom: 3rem;
        margin-top: 7rem;
    }
    @include breakpoint(large) {
        margin-bottom: 5rem;
        margin-top: 0;
    }
}

#glossary-block {
    opacity: 0;  
    @include -motion-ui-defaults;
    margin: 0 auto 5rem auto;
    @include breakpoint(small) {
        padding: 2rem 0;
    }
    @include breakpoint(large) {
        padding: 0;
    }
    @include breakpoint(xxlarge) {
        padding: 0;
    }
}

#glossary-block.show {
    opacity: 1;
}

#glossary-hero span.text-center {
    display: none;
}

.glossary-intro.quote-block {
    @include breakpoint(large) {
        margin-top: 11rem;
    }
}

// .glossary-intro.quote-block h2 {
//     font-family: $bodoni-book !important;
//     color: $oil;
//     @include breakpoint(small only) {
//         padding: 5rem 0 3rem 0;
//         font-size: 1.05em;
//         line-height: 1.5rem;
//     }
//     @include breakpoint(medium) {
//         margin: 3rem auto 5rem auto;
//     }
//     @include -motion-ui-defaults;
// }

.glossary-block .tabs-content {
    background-color: transparent;
    border: none;
}

#glossary-block ul.tabs {
    background: transparent;
    @include breakpoint(small) {
        border-bottom: 1px solid $light-gray;
    }
    @include breakpoint(large) {
        border-bottom: none;
    }
}

.tabs-title > a[aria-selected='true'] {
    @include breakpoint(small) {
        background: $ultralight;
    }
    @include breakpoint(large) {
        background: transparent;
    }
}

.glossary-block .tabs-panel {
    margin-top: -.18rem;
    padding: 0;
}

div[id^="printResults_"] {
    margin-left: 1rem;
    margin-right: 1rem;
}

.glossary-block .kloAlphabetsWrapper {
    display: inline-block;
    width: 100%;
    margin: 1.25rem 0 1rem;
    background-color: transparent;
    border-top: 1px solid #CACACA;
    border-bottom: 1px solid #CACACA;
    padding: 1rem 0 1.3rem;
}

.glossary-block .tabs {
    @include breakpoint(small) {
        margin-bottom: 2rem;
    }
    @include breakpoint(medium) {
        margin-bottom: 1rem;
    }
    border: none;
}

.glossary-block .tabs-panel>div>div {
    margin-left: 0;
    margin-right: 0;
}

.glossary-block .tabs-panel>div>div>select {
    margin: 12px 0 !important;
    width: 100%;
}

.glossary-block .tabs-panel>div>div>select~div {
    width: 100%;
}

.glossary-block .tabs-panel>div>div>select~div h2 {
    @include breakpoint(medium) {
        padding-top: 0 !important;
    }
}

.glossary-block-v .tabs {
    margin-bottom: 0;
    background: transparent;
}

.glossary-block .tabs .tabs-title {
    padding: 0;
}

.glossary-block .tabs .tabs-title a,
.glossary-block-v .tabs .tabs-title a {
    padding: .35rem 0;
    text-align: left;
    color: $dark-gray;
    font-family: $bodoni-book;
    font-size: 1.25rem;
    @include -motion-ui-defaults;
    &:hover {
        color: darken($juju-red, 30%);
    }
    @include breakpoint(small) {
        border-top: 1px solid $light-gray;
    }
    @include breakpoint(large) {
        border: none;
        background: transparent;
    }
}

.glossary-block .tabs .tabs-title.is-active a {
    color: darken($juju-red, 30%);
}

.glossary-block-v .tabs,
.glossary-block-v .tabs-panel,
.glossary-block-v .tabs-content {
    border: none;
    padding: 0;
}

.glossary-block button,
.glossary-block-v button {
    font-family: $bodoni-sc-book;
    text-transform: lowercase;
    padding: 0 .52rem;
    font-size: 2rem;
}

button[disabled="true"] {
    color: $medium-gray;
}

button:not([disabled="true"]) {
    color: $dark-gray;
    @include -motion-ui-defaults;
    &:hover {
        color: $black;
    }
    &:focus {
        color: $black;
    }
}

.glossary-block select,
.glossary-block-v select {
    color: $black;
    font-family: $bodoni-book;
    background: transparent !important;
}

.glossary-block h2,
.glossary-block-v h2 {
    padding-top: 1rem;
    font-family: $bodoni-book;
}

#glossary-hero {
    float: none !important;
    @include breakpoint(small) {
        min-height: 70rem;
    }
    @include breakpoint(medium) {
        min-height: 56rem;
    }
    @include breakpoint(large) {
        min-height: 50rem;
    }
}

// #glossary-hero img {
//     width: 100%;
//     @include breakpoint(small) {
//         padding-bottom: 2rem;
//     }
//     @include breakpoint(medium) {
//         padding-bottom: 5rem;
//     }
// }

//SEARCH
input[type=search] {
    height: 2rem !important;
    margin: 0 0 .5rem !important;
    box-shadow: none !important;
    border: none !important;
    color: darken($juju-red, 30%) !important;
    @include -motion-ui-defaults;
    background-color: $ultralight !important;
}

input[type=search]:focus {
    background: $ultralight !important;
}

.searchHolder::-webkit-input-placeholder {
    color: $black !important;
}

//GLOSSARY SELECT MENU
.custom-select-menu {
    display: inline-block;
    margin-bottom: 8px;
    outline: none;
    position: relative
}

.custom-select-menu label {
    border: 2px solid #888;
    border-radius: 4px;
    color: #888;
    display: inline-block;
    font-weight: bold;
    padding: 10px 32px 10px 10px;
    position: relative;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    width: 200px
}

.custom-select-menu label.selection-made {
    color: #000
}

.custom-select-menu label:after {
    border-top: 6px solid #888;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: '';
    position: absolute;
    right: 10px;
    top: 16px
}

.custom-select-menu label.opened {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.custom-select-menu:focus label,
.custom-select-menu:hover label {
    border-color: #000
}

.custom-select-menu ul {
    background: #fff;
    border: 2px solid #000;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    left: 10px;
    list-style: none;
    margin: 0 -10px;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: scroll;
    position: absolute;
    top: 40px;
    width: 242px;
    z-index: 2
}

.custom-select-menu li {
    background-color: #fff;
    cursor: pointer;
    padding: 7px 10px
}

.custom-select-menu li:hover,
.custom-select-menu .selected {
    background-color: rgba(0, 0, 0, .05)
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//FOOTER
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.footer {
    opacity: 0;
    position: relative;
    bottom: 0;
    width: 100%;
    background: $ultralight;
    @include -motion-ui-defaults;
    @include breakpoint(small) {
        padding: 2rem 3rem 1rem !important;
    }
    @include breakpoint(medium) {
        padding: 2rem 3rem .5rem !important;
    }
    @include breakpoint(large) {
        padding: 2rem !important;
    }
}

.footer.show {
    opacity: 1;
}

.pp-content .footer {
    @include breakpoint(small) {
        position: relative;
    }
    @include breakpoint(large) {
        position: absolute;
        bottom: 0;
        z-index: 1;
    }
}

.footer>.row {
    margin: 0 !important;
}

.base-info a,
.base-info p {
    color: $black !important;
}

.base-info blockquote p,
.base-info blockquote span {
    @include breakpoint(small) {
        color: $footer-lite !important;
    }
    @include breakpoint(large) {
        color: $black !important;
    }
}

.base-info a,
#fc .base-info a {
    display: inline-block;
    margin-right: .5rem;
    border-bottom: 1px solid transparent;
    @include -motion-ui-defaults;
    &:hover {
        border-bottom: 1px solid $black;
    }
}

.base-info blockquote {
    margin: 0;
    padding: 0;
}

.base-info  blockquote p,
.base-info blockquote span {
    color: $dark-gray;
    line-height: 1.6rem;
    @include breakpoint(small) {
        text-align: center;
    }
    @include breakpoint(large) {
        text-align: left;
    }
}

.base-info a:hover span,
#fc .base-info a:hover span {
    color: initial;
}

.base-info,
.base-info p,
.base-info blockquote,
.base-info blockquote p {
    margin-bottom: 0;
    @include breakpoint(small) {
        text-align: center;
    }
    @include breakpoint(large) {
        text-align: left;
    }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//AUTOCOMPLETE
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.autocomplete-suggestions {
    text-align: left;
    cursor: default;
    border: 1px solid #ccc;
    border-top: 0;
    background: #fff;
    box-shadow: -1px 1px 3px rgba(0, 0, 0, .1);
    /* Refrain from changing core styles, unless you are sure about it */
    position: absolute;
    display: none;
    z-index: 9999;
    max-height: 254px;
    overflow: hidden;
    overflow-y: auto;
    font-size: 1.2rem;
    padding: 5px 2px;
    box-sizing: border-box;
}

.autocomplete-suggestion {
    position: relative;
    padding: 0 .6em;
    line-height: 23px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.02em;
    color: #333;
}

.autocomplete-suggestion b {
    font-weight: normal;
    color: #1f8dd6;
}

.autocomplete-suggestion.selected {
    background: #f0f0f0;
}
